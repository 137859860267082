import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';

@Component({
  selector: 'tq-weekdays-widget',
  templateUrl: './tq-weekdays-widget.component.html',
  styleUrl: './tq-weekdays-widget.component.scss'
})
export class TQWeekdaysWidgetComponent implements OnInit, OnChanges
{
  @Input({required: true}) weekstart: string;
  @Input({required: true}) weekdays: string; 
  @Output() weekdaysChange = new EventEmitter<string>();

  days = [];

  dow : any[] = [
    { name: "Mon.", value: "MO" },
    { name: "Tue.", value: "TU" },
    { name: "Wed.", value: "WE" },
    { name: "Thu.", value: "TH" },
    { name: "Fri.", value: "FR" },
    { name: "Sat.", value: "SA" },
    { name: "Sun.", value: "SU" },
  ];  

  constructor() 
  {}

  ngOnInit(): void 
  {
    if (!this.weekdays || this.weekdays.length == 0)
    {
      this.days = [];
    }
    else
    {
      this.days = this.weekdays.split(' ');
    }
  }

  // Parent component changes 
  ngOnChanges()
  {
    if (!this.weekdays || this.weekdays.length == 0)
    {
      this.days = [];
    }
    else
    {
      this.days = this.weekdays.split(' ');
    }
  } 
  
  // Local changes 
  changeDays() 
  {
    this.weekdaysChange.emit(this.days.join(' '));
  }
  

}
