import { createReducer, on } from '@ngrx/store';

import { initialTasksState } from './tasks.state';
import * as TASKS_ACTIONS from './tasks.actions';


export const tasksReducer = createReducer(
  initialTasksState,

  // TQtasks
  on(TASKS_ACTIONS.loadTasksList, (state) => ({
    ...state,
    status: 'loading' as const,
    error: null,
  })),
  on(TASKS_ACTIONS.loadTasksListOK, (state, { TQtasks }) => ({
    ...state,
    TQtasks: TQtasks,
    status: 'loaded' as const,
    error: null
  })),  
  on(TASKS_ACTIONS.loadTasksListKO, (state, { error }) => ({
    ...state,
    status: 'error' as const,
    error: error,
  })),  
  on(TASKS_ACTIONS.loadTasksListSAME, (state) => ({
    ...state,
    status: 'loaded' as const,
    error: null
  })),  
  
  on(TASKS_ACTIONS.selectTask, (state, { id }) => ({
    ...state,
    selectedTaskId: id,
  })),

);