import { Component, HostListener, OnInit, OnDestroy } from '@angular/core';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Router } from "@angular/router";

import { TaskSample } from 'src/app/models/task';

import { Store } from '@ngrx/store';
import { AppState } from 'src/app/appState/app.state';
import * as APP_ACTIONS from 'src/app/appState/app.actions';
import { appState } from 'src/app/appState/app.selectors';
import { TasksState } from 'src/app/tasks/store/tasks.state';
import * as TASKS_ACTIONS from 'src/app/tasks/store/tasks.actions';
import { tasksState } from 'src/app/tasks/store/tasks.selectors';

import { SamuraiService } from 'src/services/samurai/samurai.service';
import { TQApiService } from 'src/app/services/tqapi.service';
import { TQDateTimeService } from 'src/app/services/tqdatetime.service';
import { TQSessionService } from 'src/app/services/tqsession.service';

import { TQTaskCardComponent } from 'src/app/shared/widgets/tq-task-card/tq-task-card.component';
import { TQTaskMenuComponent } from 'src/app/shared/widgets/tq-task-menu/tq-task-menu.component';

import { faFile, faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import { faArrowUp, faCaretSquareDown, faFilter, faPlus, faRedo, faSpinner, faThumbtack, } from '@fortawesome/free-solid-svg-icons';


@Component({
  selector: 'app-workpad',
  templateUrl: './workpad.component.html',
  styleUrls: ['./workpad.component.scss']
})
export class WorkpadComponent implements OnInit, OnDestroy
{
  faArrowUp = faArrowUp;
  faCaretSquareDown = faCaretSquareDown;
  faFile = faFile;
  faFilter = faFilter;
  faPlus = faPlus;
  faRedo = faRedo;
  faQuestionCircle = faQuestionCircle;
  faSpinner = faSpinner;
  faThumbtack = faThumbtack;

  componentState = 'init';

  appState: AppState;
  appStateSubs: any;

  tasksState: TasksState;
  tasksStateSubs: any;
  canAddTask: boolean = true;


  TQTasksCol1: any = []; 
  TQTasksCol2: any = []; 
  TQTasksCol3: any = []; 
  TQTasksCol4: any = []; 
  TQTasksCol5: any = []; 

  taskCol1title: string = "planning";
  taskCol2title: string = "todo";
  taskCol3title: string = "doing";
  taskCol4title: string = "waiting";
  taskCol5title: string = "stopped";

  TQsession: any;
  prefWorkpadNumCols = 5;

  // Workpad status
  taskId: number = 0; // Local copy of tasksState.selectedTaskId
  taskText: string = "";

  workpadLoading: boolean = false;

  workpadFilter: boolean = false;
  workpadFilterRegEx: string  = null ;

  constructor(
    private router: Router,
    private store: Store,
    public  samApp: SamuraiService,
    private tqApi: TQApiService,
    public  tqDT: TQDateTimeService,
    public  tqSession: TQSessionService,
  ) {}

  ngOnInit()
  {
    this.store.dispatch(APP_ACTIONS.setBackURL({ url:this.router.url }))    
    this.store.dispatch(APP_ACTIONS.enterTQpane( {pane:'workpad'} ))

    this.appStateSubs = this.store.select(appState)
      .subscribe( state => {
        this.appState = state 
        if (state.status != 'loaded') return;
        if (this.appState.activePane != 'workpad') return;

        this.TQsession = this.appState 
        this.prefWorkpadNumCols = this.TQsession['prefWorkpadNumCols'] || 5
        if (this.samApp.onMobile)
        {
          this.taskCol1title = this.TQsession['prefWorkpadCol1Mobile'] || "planning"
          this.taskCol2title = this.TQsession['prefWorkpadCol2Mobile'] || "todo"
          this.taskCol3title = this.TQsession['prefWorkpadCol3Mobile'] || "doing"
          this.taskCol4title = this.TQsession['prefWorkpadCol4Mobile'] || "waiting"
          this.taskCol5title = this.TQsession['prefWorkpadCol5Mobile'] || "stopped"
        }
        else
        {
          this.taskCol1title = this.TQsession['prefWorkpadCol1'] || "planning"
          this.taskCol2title = this.TQsession['prefWorkpadCol2'] || "todo"
          this.taskCol3title = this.TQsession['prefWorkpadCol3'] || "doing"
          this.taskCol4title = this.TQsession['prefWorkpadCol4'] || "waiting"
          this.taskCol5title = this.TQsession['prefWorkpadCol5'] || "stopped"
        }
   
        if (this.componentState == 'loaded')
        {
          this.canAddTask = this.tqSession.canAddTask()

          this.buildWorkpadLists();      
        }
      })

    this.tasksStateSubs = this.store.select(tasksState)
      .subscribe( state => {
        this.tasksState = state 
        if (state.status != 'loaded') return;
        if (this.appState.activePane != 'workpad') return;

        if (this.componentState == 'loaded')
        {
          this.canAddTask = this.tqSession.canAddTask()

          this.taskId=state.selectedTaskId;
          this.buildWorkpadLists();      
        }
      })
     
    this.workpadFilter=this.samApp.retrieveStore("TQworkpad.filter", "session")
    this.workpadFilterRegEx=this.samApp.retrieveStore("TQworkpad.filterRegEx", "session")

    // Prevent early call on page reload
    if (this.tqSession.getStatus() == 'loaded')  
    {
      this.store.dispatch(TASKS_ACTIONS.loadTasksList());
    }
        
    this.componentState = 'loaded';
  }

  ngOnDestroy()
  {
    this.appStateSubs.unsubscribe();
    this.tasksStateSubs.unsubscribe();
  }

  @HostListener('document:keydown.control.f', ['$event'])
  CtrlF(event: any)
  {
    event.preventDefault();
    this.clearWorkpadFilter();
    this.workpadFilter = true;

    this.samApp.saveStore("TQworkpad.filter", this.workpadFilter, "session")
    this.samApp.saveStore("TQworkpad.filterRegEx", this.workpadFilterRegEx, "session")
  }
  @HostListener('document:keydown.escape', ['$event'])
  Escape(event: any)
  {
    event.preventDefault();
    if (this.workpadFilter == true)
    {
      this.clearWorkpadFilter();
      this.workpadFilter = false;

      this.samApp.saveStore("TQworkpad.filter", this.workpadFilter, "session")
      this.samApp.saveStore("TQworkpad.filterRegEx", this.workpadFilterRegEx, "session")
    }
    else if (this.taskId == 0)
    {
      this.clearProjectFilter()
    }
    else     
    {
      this.clearToTop()
    }
  }


  drop(event: CdkDragDrop<string[]>)
  {
    if (event.previousContainer === event.container) return;

    this.updateTask(event.item.data, "status", event.container.data.toString());

    this.store.dispatch(TASKS_ACTIONS.selectTask({id: event.item.data}))
  }


  scrollToPaneTitle()
  {
    document.getElementById("workpadPane").scrollIntoView({ behavior: 'smooth' });
  }
  

  clearToTop()
  {
    this.store.dispatch(TASKS_ACTIONS.selectTask({id: 0}))   
    this.scrollToPaneTitle()
  }

  scrollToTask()
  {
    // Do not scroll when filter is active
    if (this.workpadFilter == true) return;

    // No task where to scroll
    if (this.taskId == null) return;

    // First search in Col1
    var elem = document.getElementById("col1-"+this.taskId.toString());
    if (elem != null)
    {
      elem.scrollIntoView({inline: 'start', block: 'center'});
      return;
    }
    // Then search in Col2
    var elem = document.getElementById("col2-"+this.taskId.toString());
    if (elem != null)
    {
      elem.scrollIntoView({inline: 'start', block: 'center'});
      return;
    }
    // Search in Col3
    var elem = document.getElementById("col3-"+this.taskId.toString());
    if (elem != null)
    {
      elem.scrollIntoView({inline: 'start', block: 'center'});
      return
    }
    // Search in Col4
    var elem = document.getElementById("col4-"+this.taskId.toString());
    if (elem != null)
    {
      elem.scrollIntoView({inline: 'start', block: 'center'});
      return
    }
    // Search in Col5
    var elem = document.getElementById("col5-"+this.taskId.toString());
    if (elem != null)
    {
      elem.scrollIntoView({inline: 'start', block: 'center'});
      elem.focus();
      return
    }
  }


  editActivity(t?: any)
  {
    if (t == null)
    {
      this.taskId=0;
    }
    else
    {
      this.taskId=t["id"];
    }
    // Leaving component
    this.store.dispatch(APP_ACTIONS.activateTQpane({ pane:'activity' })) 

    this.store.dispatch(TASKS_ACTIONS.selectTask({ id: this.taskId }))

    this.samApp.saveStore("TQworkpad.filter", this.workpadFilter, "session")
    this.samApp.saveStore("TQworkpad.filterRegEx", this.workpadFilterRegEx, "session")

    this.router.navigate(['/activity'], { queryParams: { task: this.taskId } });
  }

  editTask(t?: any)
  {
    if (t == null)
    {
      this.taskId=0;
      this.taskText="";
    }
    else
    {
      this.taskId=t["id"];
      this.taskText=t["title"];
    }
    // Leaving component
    this.store.dispatch(APP_ACTIONS.activateTQpane({ pane:'task' })) 

    this.store.dispatch(TASKS_ACTIONS.selectTask({ id: this.taskId }))

    this.samApp.saveStore("TQworkpad.filter", this.workpadFilter, "session")
    this.samApp.saveStore("TQworkpad.filterRegEx", this.workpadFilterRegEx, "session")

    this.router.navigate(['/task', this.taskId])
  }

  async updateTask
  (
    id: number,
    attribute: string,
    value: string,
  )
  {
    var TQTask = {
      "id"         : id,
      "profile_id" : this.appState.TQprofileId,
    }
    // if (attribute=="start")
    // {
    //   TQTask['status']="doing"
    //   TQTask['startWorkDate']=this.tqDT.tqDateISOString;
    // }
    // if (attribute=="stop")
    // {
    //   TQTask['status']="stopped"
    // }
    // if (attribute=="end")
    // {
    //   TQTask['status']="done"
    //   TQTask['endWorkDate']=this.tqDT.tqDateISOString;
    // }
    if (attribute=="status")
    {
      TQTask['status']=value
    }
    if (attribute=="priority")
    {
      TQTask['priority']=value
    }
    if (attribute=="relDate")
    {
      if (value == "not pinned") 
      {
        TQTask['relDate']='future'
      }
      if (value == "current day") 
      {
        TQTask['relDate']='+0d'
      }
      if (value == "next day") 
      {
        TQTask['relDate']='+1d'
      }
    }
    // TODO catch
    let res = await this.tqApi.updateTask(TQTask)

    this.store.dispatch(TASKS_ACTIONS.selectTask({id: res['id']}))
    this.store.dispatch(TASKS_ACTIONS.loadTasksList());
    //this.buildWorkpadLists()
  }

  async duplicateTask(taskId: any)
  {
    let res = await this.tqApi.duplicateTask(taskId);
    
    this.store.dispatch(TASKS_ACTIONS.selectTask({id: res['id']}))
    this.store.dispatch(TASKS_ACTIONS.loadTasksList());

    // this.buildWorkpadLists()
  }

  buildWorkpadLists()
  {
    this.workpadLoading = true;
    
    this.TQTasksCol1 = this.bubbleUpTodayTasks(this.getTQTasksCol1(), this.tqDT.tqDateString)
    this.TQTasksCol2 = this.bubbleUpTodayTasks(this.getTQTasksCol2(), this.tqDT.tqDateString)
    this.TQTasksCol3 = this.bubbleUpTodayTasks(this.getTQTasksCol3(), this.tqDT.tqDateString)
    this.TQTasksCol4 = this.bubbleUpTodayTasks(this.getTQTasksCol4(), this.tqDT.tqDateString)
    this.TQTasksCol5 = this.bubbleUpTodayTasks(this.getTQTasksCol5(), this.tqDT.tqDateString)

    setTimeout( () => this.scrollToTask(), 0)

    this.workpadLoading = false;
  }

  getTQTasksByStatus(status: string)
  {
    var res: Array<any> = [];

    if (this.tasksState.TQtasks == null) return res; 
    
    for (var i = 0; i < this.tasksState.TQtasks.length; i++)
    {
      var t=this.tasksState.TQtasks[i];

      // Filter by project list
      if (this.appState.projectFilterCode != '')
      {
        if (this.appState.projectFilterList.indexOf(t.project_id) < 0) continue;
      }

      if (this.filterTask(t)) continue;

      if (status == 'any' || status == t['status'])
      {
        res.push(t)
        continue;
      }
    }

    return this.orderTasks(res)
  }

  orderTasks(tasks)
  {
    var da: string;
    var db: string;

    return tasks.sort((a, b) =>
    {
      // return task ordered by priority
      if (a.priority < b.priority) return -1;
      if (a.priority > b.priority) return 1;

      da=a.dueDate; 
      db=b.dueDate; 
      // on same priority, send non-due tasks to the bottom
      if (da == "" || da == null) da=this.tqDT.biggestDate()
      if (db == "" || db == null) db=this.tqDT.biggestDate()
      // on same priority, return task ordered by due date
      if (this.tqDT.isDateBefore(da, db)) return -1;
      if (this.tqDT.isDateAfter(da, db)) return 1;

      // on same priority and same due date, return task ordered by id
      return (a.id < b.id) ? -1 : 1;
    })
  }

  // orderTasksAfterTime(tasks)
  // {
  //   var ea: string;
  //   var eb: string;
  //   var da: string;
  //   var db: string;

  //   return tasks.sort((a, b) =>
  //   {
  //     ea=a.exeTime; if (ea == "" || ea == null) ea=this.tqDT.biggestDate()
  //     eb=b.exeTime; if (eb == "" || eb == null) eb=this.tqDT.biggestDate()
  //     if (ea > eb) return 1;
  //     if (ea < eb) return -1;

  //     // return task ordered by priority
  //     if (a.priority > b.priority) return 1;
  //     if (a.priority < b.priority) return -1;

  //     da=a.dueDate; if (da == "" || da == null) da=this.tqDT.biggestDate()
  //     db=b.dueDate; if (db == "" || db == null) db=this.tqDT.biggestDate()
  //     if (this.tqDT.isDateAfter(da, db)) return 1;
  //     if (this.tqDT.isDateBefore(da, db))  return -1;
  //   })
  // }

  /**
   * Returns due-today tasks at top of an already ordered list 
   * Beware that this function may be called twice!!
   */
  bubbleUpTodayTasks(tasks: any[], date: string)
  {
    if (tasks.length == 0) return;     

    let da: any;
    let db: any;
    let ta: any;
    let tb: any;
    let xa: any;
    let xb: any;

    tasks.sort((a, b) => {
      da = a.dueDate || "";
      db = b.dueDate || "";
      ta = a.targetDate || "";
      tb = b.targetDate || "";

      xa = da ? da : ta || "" ;
      xb = db ? db : tb || "";

      // Set today-due dates to the top
      if (da==db)
      {
        // Set today-target dates to the top
        if (ta==tb)
        {
          if (a.priority < b.priority) return -1;
          if (a.priority > b.priority) return 1; 

          if (xa == xb) return (+a.id <= +b.id) ? -1 : 1;
          if (xa == "") return 1;
          if (xb == "") return -1;
          if (this.tqDT.isDateBefore(xa, xb))  return -1;
          if (this.tqDT.isDateAfter(xa, xb)) return 1;
    
          return (+a.id <= +b.id) ? -1 : 1;
        }
        else
        {
          if (ta == date) return -1;
          if (tb == date) return 1; 

          if (a.priority < b.priority) return -1;
          if (a.priority > b.priority) return 1; 

          if (xa == xb) return (+a.id <= +b.id) ? -1 : 1;
          if (xa == "") return 1;
          if (xb == "") return -1;
          if (this.tqDT.isDateBefore(xa, xb))  return -1;
          if (this.tqDT.isDateAfter(xa, xb)) return 1;
    
          return (+a.id <= +b.id) ? -1 : 1;
        }
      }
      else
      {
        if (da == date) return -1;
        if (db == date) return 1;   
  
        // Set today-target dates to the top
        if (ta==tb)
        {
          if (a.priority < b.priority) return -1;
          if (a.priority > b.priority) return 1; 

          if (xa == xb) return (+a.id <= +b.id) ? -1 : 1;
          if (xa == "") return 1;
          if (xb == "") return -1;
          //if (xa == xb) return 0;
          if (this.tqDT.isDateBefore(xa, xb))  return -1;
          if (this.tqDT.isDateAfter(xa, xb)) return 1;
    
          return (+a.id <= +b.id) ? -1 : 1;
        }
        else
        {
          if (ta == date) return -1;
          if (tb == date) return 1; 

          if (a.priority < b.priority) return -1;
          if (a.priority > b.priority) return 1; 

          if (xa == xb) return (+a.id <= +b.id) ? -1 : 1;
          if (xa == "") return 1;
          if (xb == "") return -1;
          //if (xa == xb) return 0;
          if (this.tqDT.isDateBefore(xa, xb))  return -1;
          if (this.tqDT.isDateAfter(xa, xb)) return 1;
    
          return (+a.id <= +b.id) ? -1 : 1;
        }
      }
    })

    return tasks
  }

  getTQTasksCol1()
  {
    return this.getTQTasksByStatus(this.taskCol1title);
  }

  getTQTasksCol2()
  {
    return this.getTQTasksByStatus(this.taskCol2title);
  }

  getTQTasksCol3()
  {
    return this.getTQTasksByStatus(this.taskCol3title);
  }

  getTQTasksCol4()
  {
    return this.getTQTasksByStatus(this.taskCol4title);
  }

  getTQTasksCol5()
  {
    return this.getTQTasksByStatus(this.taskCol5title);
  }


  updateTasksCol1(newTitle)
  {
    this.taskCol1title=newTitle

    this.TQTasksCol1=this.getTQTasksCol1();

    // Update TQsession
    this.updateProfilePrefWorkpad(1, newTitle)

    this.TQsession = this.samApp.retrieveStore("TQsession")
    if (this.samApp.onMobile)
    {
      this.TQsession["prefWorkpadCol1Mobile"]=this.taskCol1title
    }
    else
    {
      this.TQsession["prefWorkpadCol1"]=this.taskCol1title
    }
    this.samApp.saveStore("TQsession", this.TQsession)

    this.store.dispatch(APP_ACTIONS.setTQprofilePrefs({profile: this.TQsession}))      
}

  updateTasksCol2(newTitle)
  {
    this.taskCol2title=newTitle

    this.TQTasksCol2=this.getTQTasksCol2();

    // Update TQsession
    this.updateProfilePrefWorkpad(2, newTitle)

    this.TQsession = this.samApp.retrieveStore("TQsession")
    if (this.samApp.onMobile)
    {
      this.TQsession["prefWorkpadCol2Mobile"]=this.taskCol2title
    }
    else
    {
      this.TQsession["prefWorkpadCol2"]=this.taskCol2title
    }
    this.samApp.saveStore("TQsession", this.TQsession)

    this.store.dispatch(APP_ACTIONS.setTQprofilePrefs({profile: this.TQsession}))      
  }

  updateTasksCol3(newTitle)
  {
    this.taskCol3title=newTitle

    this.TQTasksCol3=this.getTQTasksCol3();

    // Update TQsession
    this.updateProfilePrefWorkpad(3, newTitle)

    this.TQsession = this.samApp.retrieveStore("TQsession")
    if (this.samApp.onMobile)
    {
      this.TQsession["prefWorkpadCol3Mobile"]=this.taskCol3title
    }
    else
    {
      this.TQsession["prefWorkpadCol3"]=this.taskCol3title
    }
    this.samApp.saveStore("TQsession", this.TQsession)

    this.store.dispatch(APP_ACTIONS.setTQprofilePrefs({profile: this.TQsession}))      
  }

  updateTasksCol4(newTitle)
  {
    this.taskCol4title=newTitle

    this.TQTasksCol4=this.getTQTasksCol4();

    // Update TQsession
    this.updateProfilePrefWorkpad(4, newTitle)

    this.TQsession = this.samApp.retrieveStore("TQsession")
    if (this.samApp.onMobile)
    {
      this.TQsession["prefWorkpadCol4Mobile"]=this.taskCol4title
    }
    else
    {
      this.TQsession["prefWorkpadCol4"]=this.taskCol4title
    }
    this.samApp.saveStore("TQsession", this.TQsession)

    this.store.dispatch(APP_ACTIONS.setTQprofilePrefs({profile: this.TQsession}))      
  }

  updateTasksCol5(newTitle)
  {
    this.taskCol5title=newTitle

    this.TQTasksCol5=this.getTQTasksCol5();

    // Update TQsession
    this.updateProfilePrefWorkpad(5, newTitle)

    this.TQsession = this.samApp.retrieveStore("TQsession")
    if (this.samApp.onMobile)
    {
      this.TQsession["prefWorkpadCol5Mobile"]=this.taskCol5title
    }
    else
    {
      this.TQsession["prefWorkpadCol5"]=this.taskCol5title
    }
    this.samApp.saveStore("TQsession", this.TQsession)

    this.store.dispatch(APP_ACTIONS.setTQprofilePrefs({profile: this.TQsession}))      
  }

  async updateProfilePrefWorkpad
  (
    column: number,
    title: string
  )
  {
    var profile = {}
    switch (column)
    {
      case 1:
        if (this.samApp.onMobile)
        {
          profile['prefWorkpadCol1Mobile'] = title
        }
        else
        {
          profile['prefWorkpadCol1'] = title
        }
        break
      case 2:
        if (this.samApp.onMobile)
        {
          profile['prefWorkpadCol2Mobile'] = title
        }
        else
        {
          profile['prefWorkpadCol2'] = title
        }
        break
      case 3:
        if (this.samApp.onMobile)
        {
          profile['prefWorkpadCol3Mobile'] = title
        }
        else
        {
          profile['prefWorkpadCol3'] = title
        }
        break
      case 4:
        if (this.samApp.onMobile)
        {
          profile['prefWorkpadCol4Mobile'] = title
        }
        else
        {
          profile['prefWorkpadCol4'] = title
        }
        break
      case 5:
        if (this.samApp.onMobile)
        {
          profile['prefWorkpadCol5Mobile'] = title
        }
        else
        {
          profile['prefWorkpadCol5'] = title
        }
        break
      }

    await this.tqApi.updateProfile(profile)
  }

  getColumnWidth()
  {
    return (100/this.prefWorkpadNumCols)+"%";
  }

  clearWorkpadFilter()
  {
    this.workpadFilterRegEx = null;
    this.samApp.deleteStore("TQworkpad.filterRegEx", "session")
    this.samApp.deleteStore("TQworkpad.filter", "session")

    this.buildWorkpadLists()

    var elem=document.getElementById("WorkpadFilter")
    setTimeout(() =>
    {
      elem.focus();
      elem.scrollIntoView({behavior: 'smooth', inline: 'center', block: 'center'});
    }, 0);
  }

  updateWorkpadFilter(newFilter)
  {
    this.workpadFilterRegEx=newFilter

    this.buildWorkpadLists()
  }

  filterTask(t: any): boolean
  {
    if (this.workpadFilterRegEx == null) return false

    this.samApp.saveStore("TQworkpad.filter", this.workpadFilter, "session")
    this.samApp.saveStore("TQworkpad.filterRegEx", this.workpadFilterRegEx, "session")

    // Return whether the task title matches the filter
    return (t['title'].match(new RegExp(this.workpadFilterRegEx,"i")) == null)? true:false;
  }

  clearProjectFilter()
  {
    this.store.dispatch(APP_ACTIONS.projectFiltered({ id: 0, code:'', color:'', list:[]}))
  }

  showProjectCode(code:string)
  {
    return true; 
    
    // Show codes on aggregated mode
    if (this.appState.projectFilterCode == '')   return true;
    if (this.appState.projectFilterCode != code) return true;
    return false;
  }

  onTaskMenuCommand(command: string, task: any)
  {
    switch(command)
    {
      case "Log Activity":
        this.editActivity(task);
        break;
      case "Edit":
        this.editTask(task);
        break;
      case "Duplicate":
        this.duplicateTask(task.id);
        break;
      case "planning":
      case "todo":
      case "doing":
      case "waiting":
      case "stopped":
      case "done":
      case "canceled":
        this.updateTask(task.id, "status", command);
        break;
      case "A":
      case "B":
      case "C":
      case "D":
      case "E":
        this.updateTask(task.id, "priority", command);
        break;
      case "not pinned":
      case "current day":
      case "next day":
        this.updateTask(task.id, "relDate", command);
        break;
      case "Select":
        this.store.dispatch(TASKS_ACTIONS.selectTask({id: task.id}))
        break;
     }
  }

}
