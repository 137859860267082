import { Component, HostListener, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";

import * as TASK from 'src/app/models/task';

import { Store } from '@ngrx/store';
import { AppState } from 'src/app/appState/app.state';
import * as APP_ACTIONS from 'src/app/appState/app.actions';
import { appState } from 'src/app/appState/app.selectors';
import { TasksState } from 'src/app/tasks/store/tasks.state';
import * as TASKS_ACTIONS from 'src/app/tasks/store/tasks.actions';
import { tasksState } from 'src/app/tasks/store/tasks.selectors';

import { SamuraiService } from 'src/services/samurai/samurai.service';
import { TQApiService } from 'src/app/services/tqapi.service';
import { TQDateTimeService } from 'src/app/services/tqdatetime.service';
import { TQSessionService } from 'src/app/services/tqsession.service';

import { TQTaskMenuComponent } from 'src/app/shared/widgets/tq-task-menu/tq-task-menu.component';

import { faFile, faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import { faArrowUp, 
         faCaretLeft, faCaretRight,
         faCaretSquareDown, faCaretSquareLeft, faCaretSquareRight, faCaretSquareUp, 
         faFilter, faPlus, faRedo, faSpinner, faThumbtack}
  from '@fortawesome/free-solid-svg-icons';


@Component({
  selector: 'app-todopad',
  templateUrl: './todopad.component.html',
  styleUrls: ['./todopad.component.scss']
})
export class TodopadComponent implements OnInit, OnDestroy
{
  faArrowUp = faArrowUp;
  faCaretLeft = faCaretLeft;
  faCaretRight = faCaretRight;
  faCaretSquareDown = faCaretSquareDown;
  faCaretSquareLeft = faCaretSquareLeft;
  faCaretSquareRight = faCaretSquareRight;
  faCaretSquareUp = faCaretSquareUp;
  faFile = faFile;
  faFilter = faFilter;
  faPlus = faPlus;
  faRedo = faRedo;
  faQuestionCircle = faQuestionCircle;
  faSpinner = faSpinner;
  faThumbtack = faThumbtack;

  componentState = 'init';

  appState: AppState;
  appStateSubs: any;

  tasksState: TasksState;
  tasksStateSubs: any;
  canAddTask: boolean = true;

  // Todopad Tasks
  TQTasksAgenda: any = [];
  TQTasksTodo: any = [];
  TQTasksOnHold: any = [];
  TQTasksPastDate: any = [];

  agendaTitle = "";
  todoTitle = "";
  taskCol3: string = "tomorrow";

  // Current task
  taskId: number = 0; // Local copy of tasksState.selectedTaskId
  taskText: string = "";

  showOtherDays = true;
  showAllTasks = false;

  todopadLoading: boolean = false;

  todopadFilter: boolean = false;
  todopadFilterRegEx: string  = null;

  TQSession: any;

  todopadDateTime = null;     // Current datetime in todopad
  todopadDateISOString = "";  // Current date in ISO format
  todopadDateString = "";     // Profile based display date of the current date
  todopadDayName = "";        // Day name of the current date
  todopadWeekday = null; 

  constructor(
    private router: Router,
    private activeRoute: ActivatedRoute,
    private store: Store,
    public  samApp: SamuraiService,
    public  tqDT: TQDateTimeService,
    private tqApi: TQApiService,
    public  tqSession: TQSessionService,
  ) {}

  ngOnInit()
  {
    this.store.dispatch(APP_ACTIONS.setBackURL({ url:this.router.url }))    
    this.store.dispatch(APP_ACTIONS.enterTQpane( {pane:'todopad'} ))

    this.appStateSubs = this.store.select(appState)
      .subscribe(state => {
        this.appState = state 
        if (state.status != 'loaded') return;
        if (this.appState.activePane != 'todopad') return;

        this.taskCol3=this.samApp.retrieveStore("TQtodopad.taskCol3", "session") || "tomorrow"

        if (this.componentState == 'loaded')
        {
          this.canAddTask = this.tqSession.canAddTask()

          this.buildTodopadLists();      
        }
      })
  
    this.tasksStateSubs = this.store.select(tasksState)
      .subscribe( state => {
        this.tasksState = state 
        if (state.status != 'loaded') return;
        if (this.appState.activePane != 'todopad') return;

        if (this.componentState == 'loaded')
        {
          this.canAddTask = this.tqSession.canAddTask()

          // Update local copies
          this.taskId=state.selectedTaskId;
          
          this.buildTodopadLists();      
        }
      })

    this.showAllTasks = this.samApp.retrieveStore("TQtodopad.showAllTasks", "session")
    if (this.showAllTasks === null)
    {
      this.showAllTasks = false;
    }

    this.showOtherDays = this.samApp.retrieveStore("TQtodopad.showOtherDays", "session")
    if (this.showOtherDays === null)
    {
      this.showOtherDays = true;
    }
  
    this.todopadDateISOString = this.samApp.retrieveStore("TQtodopad.day", "session")
    if (!this.tqDT.isValidDateISO(this.todopadDateISOString))
    {
      this.todopadDateISOString = this.tqDT.tqDateISOString
    }    
    this.todopadDateTime = this.tqDT.toLuxonFromISO(this.todopadDateISOString)
    this.todopadDayName = this.tqDT.formatDayName(this.todopadDateTime); 
    this.todopadDateString = this.tqDT.formatDate(this.todopadDateTime)
    
    // Go to date in params or to the current date from session store
    let date = this.activeRoute.snapshot.queryParams['date']
    if ( date != undefined && this.tqDT.isValidDateISO(date) )
    {
      this.goToDate(date)
    }
    else
    {
      this.goToDate(this.todopadDateISOString)
    }

    this.todopadFilter=this.samApp.retrieveStore("TQtodopad.filter", "session")
    this.todopadFilterRegEx=this.samApp.retrieveStore("TQtodopad.filterRegEx", "session")

    // Prevent early call on page reload
    if (this.tqSession.getStatus() == 'loaded')  
    {
      this.store.dispatch(TASKS_ACTIONS.loadTasksList());
    }

    this.componentState = 'loaded';
  }

  ngOnDestroy()
  {
    this.appStateSubs.unsubscribe();
    this.tasksStateSubs.unsubscribe();
  }
  
  @HostListener('document:keydown.control.f', ['$event'])
  CtrlF(event: any)
  {
    event.preventDefault();
    this.clearTodopadFilter();
    this.todopadFilter = true;

    this.samApp.saveStore("TQtodopad.filter", this.todopadFilter, "session")
    this.samApp.saveStore("TQtodopad.filterRegEx", this.todopadFilterRegEx, "session")
  }
  @HostListener('document:keydown.escape', ['$event'])
  Escape(event: any)
  {
    event.preventDefault();
    if (this.todopadFilter == true)
    {
      this.clearTodopadFilter();
      this.todopadFilter = false;

      this.samApp.saveStore("TQtodopad.filter", this.todopadFilter, "session")
      this.samApp.saveStore("TQtodopad.filterRegEx", this.todopadFilterRegEx, "session")
    }
    else if (this.taskId == 0)
    {
      this.clearProjectFilter()
    }
    else     
    {
      this.clearToTop()
    }
  }
  
  @HostListener('document:keydown.home', ['$event'])
  ThisDay(event: any)
  {
    event.preventDefault();
    this.goToday();
  }
  @HostListener('document:keydown.pageDown', ['$event'])
  NextDay(event: any)
  {
    event.preventDefault();
    this.goNextDay();
  }
  @HostListener('document:keydown.pageUp', ['$event'])
  PreviousDay(event: any)
  {
    event.preventDefault();
    this.goPreviousDay()
  }

  
  scrollToPaneTitle()
  {
    document.getElementById("todopadPane").scrollIntoView({ behavior: 'smooth' });
  }
  

  clearToTop()
  {
    this.store.dispatch(TASKS_ACTIONS.selectTask({id: 0}))   
    this.scrollToPaneTitle()
  }

  scrollToTask()
  {
    // Do not scroll when filter is active
    if (this.todopadFilter == true) return;

    // No task where to scroll
    if (this.taskId == null || this.taskId == 0) return;

    // First search in Col1
    var elem = document.getElementById("col1-"+this.taskId.toString());
    if (elem != null)
    {
      elem.scrollIntoView({inline: 'start', block: 'center'});
      return;
    }
    // Second, search in Col2
    var elem = document.getElementById("col2-"+this.taskId.toString());
    if (elem != null)
    {
      elem.scrollIntoView({inline: 'start', block: 'center'});
      return;
    }
    // Then search in Col3
    var elem = document.getElementById("col3-"+this.taskId.toString());
    if (elem != null)
    {
      elem.scrollIntoView({inline: 'start', block: 'center'});
      return
    }
    // Then search in Col4
    var elem = document.getElementById("col4-"+this.taskId.toString());
    if (elem != null)
    {
      elem.scrollIntoView({inline: 'start', block: 'center'});
      return
    }
  }

  async goToDate(d:string)
  {
    this.todopadDateTime = this.tqDT.toLuxonFromISO(d);  
    this.buildTodopadLists()
    this.samApp.saveStore("TQtodopad.day", this.todopadDateISOString, "session")
  }

  async goToday()
  {
    this.todopadDateTime = this.tqDT.tqDateTime;
    this.buildTodopadLists()
    this.samApp.saveStore("TQtodopad.day", this.todopadDateISOString, "session")
  }

  async goNextDay()
  {
    this.todopadDateTime = this.tqDT.nextDay(this.todopadDateTime)
    this.buildTodopadLists()
    this.samApp.saveStore("TQtodopad.day", this.todopadDateISOString, "session")
  }

  async goPreviousDay()
  {
    this.todopadDateTime = this.tqDT.previousDay(this.todopadDateTime)
    this.buildTodopadLists()
    this.samApp.saveStore("TQtodopad.day", this.todopadDateISOString, "session")
  }


  editActivity(t?: any)
  {
    if (t == null)
    {
      this.taskId=0;
    }
    else
    {
      this.taskId=t["id"];
    }
    // Leaving component
    this.store.dispatch(APP_ACTIONS.activateTQpane({ pane:'activity' })) 

    this.store.dispatch(TASKS_ACTIONS.selectTask({ id: this.taskId }))

    this.samApp.saveStore("TQtodopad.filter", this.todopadFilter, "session")
    this.samApp.saveStore("TQtodopad.filterRegEx", this.todopadFilterRegEx, "session")

    this.router.navigate(['/activity'], { queryParams: { task: this.taskId } });  
  }
  
  editTask(t?: any)
  {
    if (t == null)
    {
      this.taskId=0;
      this.taskText="";
    }
    else
    {
      this.taskId=t.id;
      this.taskText=t.title;
    }
    // Leaving component
    this.store.dispatch(APP_ACTIONS.activateTQpane({ pane:'task' })) 

    this.store.dispatch(TASKS_ACTIONS.selectTask({id: this.taskId}))

    // Save Todopad state
    this.samApp.saveStore("TQtodopad.filter", this.todopadFilter, "session")
    this.samApp.saveStore("TQtodopad.filterRegEx", this.todopadFilterRegEx, "session")

    if (this.taskId == 0) 
    {
      // Set new task values
      this.router.navigate(['/task', this.taskId], { 
        queryParams: { 
          from: "todopad", 
          YYYY: this.todopadDateTime.year,
          MM: this.todopadDateTime.month,
          DD: this.todopadDateTime.day 
        }
      })
    }
    else
    {
      this.router.navigate(['/task', this.taskId])
    }
  }

  async updateTask
  (
    id: number,
    attribute: string,
    value: string,
  )
  {
    var TQTask = {
      "id"         : id,
      "profile_id" : this.appState.TQprofileId,
    }
    // if (attribute=="start")
    // {
    //   TQTask['status']="doing"
    //   TQTask['startWorkDate']=this.todopadDateString
    // }
    // if (attribute=="stop")
    // {
    //   TQTask['status']="stopped"
    // }
    // if (attribute=="end")
    // {
    //   TQTask['status']="done"
    //   TQTask['endWorkDate']=this.todopadDateString
    // }
    if (attribute=="status")
    {
      TQTask['status']=value
    }
    if (attribute=="priority")
    {
      TQTask['priority']=value
    }
    if (attribute=="relDate")
    {
      if (value == "not pinned") 
      {
        TQTask['relDate']='future'
      }
      if (value == "current day") 
      {
        TQTask['relDate']='+0d'
      }
      if (value == "next day") 
      {
        TQTask['relDate']='+1d'
      }
    }
    // TODO catch
    let res = await this.tqApi.updateTask(TQTask)

    this.store.dispatch(TASKS_ACTIONS.selectTask({id: res['id']}))
    
    this.store.dispatch(TASKS_ACTIONS.loadTasksList());
    this.buildTodopadLists()
  }

  async duplicateTask(taskId: any)
  {
    let res = await this.tqApi.duplicateTask(taskId);

    this.store.dispatch(TASKS_ACTIONS.selectTask({id: res['id']}))
    this.taskId = res['id'];

    this.store.dispatch(TASKS_ACTIONS.loadTasksList());
    this.buildTodopadLists()
  }


  buildTodopadLists()
  {
    if (this.todopadDateTime == null) return; 
    if (this.todopadDateTime.isValid == false) return; 

    this.computeDates()

    this.todopadLoading = true;
    
    this.TQTasksAgenda = this.getTQTasksAgenda() 
    this.TQTasksTodo = this.bubbleUpTodayTasks(this.getTQTasksTodo(), this.todopadDateString) 
    this.TQTasksOnHold = this.bubbleUpTodayTasks(this.getTasksOnHold(), this.todopadDateString) 
    this.TQTasksPastDate = this.getTasksPastDate()

    setTimeout( () => this.scrollToTask(), 0)

    this.todopadLoading = false;
  }

  computeDates() 
  {
    this.todopadDateISOString = this.tqDT.formatDateISO(this.todopadDateTime);
    this.todopadDateString = this.tqDT.formatDate(this.todopadDateTime);
    this.todopadWeekday = this.tqDT.dayOfWeek(this.todopadDateString)
    this.todopadDayName = this.tqDT.formatDayName(this.todopadDateTime);

    // Adjust column titles
    this.agendaTitle = ""
    this.todoTitle = ""
    if (this.tqDT.isToday(this.todopadDateString))
    {
      this.agendaTitle = "for today"
      this.todoTitle = "for today"
    }
    if (this.tqDT.isYesterday(this.todopadDateString))
    {
      this.agendaTitle = "for yesterday"
      this.todoTitle = "for yesterday"
    }
    if (this.tqDT.isTomorrow(this.todopadDateString))
    {
      this.agendaTitle = "for tomorrow"
      this.todoTitle = "for tomorrow"
    }
  }


  getTQTasksAgenda()
  {
    var res: Array<any> = [];

    if (this.tasksState.TQtasks == null) return;

    for (var i = 0; i < this.tasksState.TQtasks.length; i++)
    {
      let t= {
        ...this.tasksState.TQtasks[i],
        start: false,
        doing: false,
        end: false,
        due: false,
        exeClockTime: "",
      }

      // Mark doing tasks
      if (t.status == "doing") t.doing = true;
      
      // Ignore not ready
      if (t.status == "planning") continue;

      // Ignore on hold tasks
      if (t.status == "waiting" || t.status == "stopped") continue;

      if (!this.showAllTasks)
      {
        // Ignore done tasks
        if (t.status == "done") continue;
        // Ignore canceled tasks
        if (t.status == "canceled") continue;
      }

      // Filter by project list
      if (this.appState.projectFilterCode != '')
      {
        if (this.appState.projectFilterList.indexOf(t.project_id) < 0) continue;
      }

      // Ignore filtered tasks
      if (this.filterTask(t)) continue;

      // Protect against null relDate (unneeded)
      if (t.relDate == null) { t.relDate="future" }

      // Ignore tasks pinned to next day
      if (t.relDate == "+1d") continue;

      // Select only tasks for the Agenda
      if (!this.belongsToAgenda(t)) continue;
     
      // Ignore undefined ongoing tasks
      //if (this.isUndefinedOngoingTask(t)) continue;

      // Ignore tasks ending before today or stating after today 
      if ( (t.startWorkDate != "" && this.tqDT.isDateAfter(t.startWorkDate, this.todopadDateString)) ||
           (t.endWorkDate != ""   && this.tqDT.isDateBefore(t.endWorkDate, this.todopadDateString))  )
      {
        // Except if task is pinned to the current day
        if (t.relDate != "+0d")
        {
          continue;
        }
      }
 
      // Show tasks starting today
      if (this.tqDT.isSameDate(t.startWorkDate, this.todopadDateString) && t.startWorkTime != null)
      {
        let newTask:any = {...t}; 

        // Add or update task
        let oldTask = res.find(t => t.id === newTask.id && t.exeTime == newTask.startWorkTime);
        let index = res.indexOf(oldTask);
        if (index >= 0)
        {
          oldTask.start = true;
          oldTask.startWorkTime = t.startWorkTime
          res[index]=oldTask
        }
        else
        {
          newTask.start = true;
          newTask.exeTime = t.startWorkTime
          newTask.exeClockTime = this.tqDT.formatClockTime(t.startWorkTime.split(":")[0], t.startWorkTime.split(":")[1])

          res.push(newTask)
        }
      }

      // Show tasks ending today
      if (this.tqDT.isSameDate(t.endWorkDate, this.todopadDateString) && t.endWorkTime != null)
      {
        let newTask:any = {...t}; 

        // Add or update task
        let oldTask = res.find(t => (t.id === newTask.id && t.exeTime == newTask.endWorkTime));
        let index = res.indexOf(oldTask);
        if (index >= 0)
        {
          oldTask.end = true;
          oldTask.endWorkTime = t.endWorkTime
          res[index]=oldTask
        }
        else
        {
          newTask.start = false;
          newTask.end = true;
          newTask.exeTime = t.endWorkTime
          newTask.exeClockTime = this.tqDT.formatClockTime(t.endWorkTime.split(":")[0], t.endWorkTime.split(":")[1])

          res.push(newTask)
        }
      }

      // Show tasks due today
      if (this.tqDT.isSameDate(t.dueDate, this.todopadDateString) && t.dueTime != null)
      {
        let newTask:any = {...t}; 

        // Add or update task
        let oldTask = res.find(t => t.id === newTask.id && t.exeTime == newTask.dueTime);
        let index = res.indexOf(oldTask);
        if (index >= 0)
        {
          oldTask.due = true;
          oldTask.dueTime = t.dueTime
          res[index]=oldTask
        }
        else
        {
          newTask.start = false;
          newTask.end = false
          newTask.due = true;
          newTask.exeTime = t.dueTime
          newTask.exeClockTime = this.tqDT.formatClockTime(t.dueTime.split(":")[0], t.dueTime.split(":")[1])

          res.push(newTask)
        }
      }         

      // Show tasks targeted for today
      if (t.targetTime && this.tqDT.isSameDate(t.targetDate, this.todopadDateString))
      {
        let newTask:any = {...t}; 

        // Add or update task
        let oldTask = res.find(t => t.id === newTask.id && t.exeTime == newTask.targetTime);
        let index = res.indexOf(oldTask);
        if (index >= 0)
        {
          oldTask.target = true;
          oldTask.targetTime = t.targetTime
          res[index]=oldTask
        }
        else
        {
          //newTask.start = false;
          //newTask.end = false
          newTask.target = true;
          newTask.exeTime = t.targetTime
          newTask.exeClockTime = this.tqDT.formatClockTime(t.targetTime.split(":")[0], t.targetTime.split(":")[1])
  
          res.push(newTask)
        }
      }         

      // Ignore ongoing task... 
      if ( (t.startWorkDate == "" || this.tqDT.isSameDateOrBefore(t.startWorkDate, this.todopadDateString) ) &&
           (t.endWorkDate == ""   || this.tqDT.isSameDateOrAfter(t.endWorkDate, this.todopadDateString)) )
      {
        if (TASK.hasRepetitionOnWeekdays(t)  && !TASK.taskRepeatsThisWeekday(t, this.todopadDateTime.weekday) ) continue;
//        if (TASK.hasRepetitionOnWeekdays2(t)  && !TASK.taskRepeatsThisWeekday2(t, this.todopadDateTime.weekday) ) continue;
        if (TASK.hasRepetitionOnNumberDay(t) && !TASK.taskRepeatsThisNumberDay(t, this.todopadDateTime.day)) continue;
        if (TASK.hasRepetitionOnMonths(t)    && !TASK.taskRepeatsThisMonth(t, this.todopadWeekday) ) continue;
      }

      // Ignore repeating task in Agenda before start or after end
      // Repeated from above ???
      // if ( (this.tqDT.isDateTimeBefore(this.todopadDateString, t.exeTime, t.startWorkDate, t.startWorkTime)) ||
      //      (this.tqDT.isDateTimeAfter(this.todopadDateString, t.exeTime, t.endWorkDate, t.endWorkTime)) )
      // {
      //   // Except if task is pinned to the current day
      //   if (t.relDate != "+0d")
      //     {
      //       continue;
      //     }
      // }
      
      // Avoid repeating task in Agenda with no exeTime
      if (t.exeTime == null || t.exeTime == "") continue;

      // Set Clock Time
      t.exeClockTime = this.tqDT.formatClockTime(t.exeTime.split(":")[0], t.exeTime.split(":")[1])

      // Accept
      let newTask:any = {...t}; 

      // Add or update task
      let oldTask = res.find(t => t.id === newTask.id && t.exeTime == newTask.exeTime);
      let index = res.indexOf(oldTask);
      if (index >= 0)
      {
        res[index]=oldTask
      }
      else
      {
        res.push(newTask)
      }     
    }

    // Finally reorder the tasks
    return this.orderTasksAfterTime(res);
  }

  belongsToAgenda(t): boolean
  {
    // If there is a time set, and the date is today, then it belongs to the todo list
    if (t.startWorkTime && this.tqDT.isSameDate(t.startWorkDate, this.todopadDateString)) return true;  
    if (t.endWorkTime   && this.tqDT.isSameDate(t.endWorkDate, this.todopadDateString)) return true;  
    if (t.dueTime       && this.tqDT.isSameDate(t.dueDate, this.todopadDateString)) return true;  
    if (t.targetTime    && this.tqDT.isSameDate(t.targetDate, this.todopadDateString)) return true;  

    if (t.exeTime != null && t.exeTime != "" ) return true;

    return false;
  }


  getTQTasksTodo()
  {
    var res: Array<any> = [];

    if (this.tasksState.TQtasks == null) return;

    for (var i = 0; i < this.tasksState.TQtasks.length; i++)
    {
      let t= {
        ...this.tasksState.TQtasks[i],
        start: false,
        doing: false,
        end: false,
        due: false,
      }

      // Ignore not ready
      if (t.status == "planning") continue;

      // Ignore on hold tasks
      if (t.status == "waiting" || t.status == "stopped") continue;

      // Mark doing tasks
      if (t.status == "doing") t.doing = true;

      if (!this.showAllTasks)
      {
        // Ignore finished tasks
        if (t.status == "canceled" || t.status == "done") continue;
      }

      // Filter by project list
      if (this.appState.projectFilterCode != '')
      {
        if (this.appState.projectFilterList.indexOf(t.project_id) < 0) continue;
      }

      // Ignore filtered tasks
      if (this.filterTask(t)) continue;

      // Protect against null relDate (unneeded)
      if (t.relDate == null) { t.relDate="future" }

      // Ignore tasks pinned to next day
      if (t.relDate == "+1d") continue;

      // Select only tasks for ToDo List
      if (!this.belongsToTodoList(t)) continue;

      // Ignore undefined ongoing tasks
      /// if (this.isUndefinedOngoingTask(t)) continue;

      // Ignore tasks ending before today or stating after today 
      if ( (t.endWorkDate != ""   && this.tqDT.isDateBefore(t.endWorkDate, this.todopadDateString)) ||
           (t.startWorkDate != "" && this.tqDT.isDateAfter(t.startWorkDate, this.todopadDateString)) )
      {
        // Except if task is pinned to today
        // if (t.relDate != "+0d")
        {
          continue;
        }
      }

      // Show tasks starting today
      if (t.startWorkDate && !t.startWorkTime && this.tqDT.isSameDate(t.startWorkDate, this.todopadDateString) ) 
      {
        let newTask:any = {...t}; 

        // Add or update task
        let oldTask = res.find(t => t.id === newTask.id);
        let index = res.indexOf(oldTask);
        if (index >= 0)
        {
          oldTask.start = true;
          res[index]=oldTask
        }
        else
        {
          newTask.start = true;
  
          res.push(newTask)
        }
      }

      // Show tasks ending today
      if (this.tqDT.isSameDate(t.endWorkDate, this.todopadDateString) && t.endWorkDate && !t.endWorkTime) 
      {
        let newTask:any = {...t}; 

        // Add or update task
        let oldTask = res.find(t => (t.id === newTask.id));
        let index = res.indexOf(oldTask);
        if (index >= 0)
        {
          oldTask.end = true;
          res[index]=oldTask
        }
        else
        {
          newTask.start = false;
          newTask.end = true;
  
          res.push(newTask)
        }
      }

      // Show tasks due today
      if (this.tqDT.isSameDate(t.dueDate, this.todopadDateString) && t.dueDate && !t.dueTime)
      {
        let newTask:any = {...t}; 

        // Add or update task
        let oldTask = res.find(t => t.id === newTask.id);
        let index = res.indexOf(oldTask);
        if (index >= 0)
        {
          oldTask.due = true;
          res[index]=oldTask
        }
        else
        {
          newTask.start = false;
          newTask.end = false
          newTask.due = true;
  
          res.push(newTask)
        }
      }      
      
      // Show tasks targeted for today
      if (t.targetDate && !t.targetTime && this.tqDT.isSameDate(t.targetDate, this.todopadDateString))
      {
        let newTask:any = {...t}; 

        // Add or update task
        let oldTask = res.find(t => t.id === newTask.id);
        let index = res.indexOf(oldTask);
        if (index >= 0)
        {
          oldTask.target = true;
          res[index]=oldTask
        }
        else
        {
          //newTask.start = false;
          //newTask.end = false
          newTask.target = true;
  
          res.push(newTask)
        }
      }      
      
      // Ignore ongoing task... 
      if ( (t.startWorkDate == "" || this.tqDT.isSameDateOrBefore(t.startWorkDate, this.todopadDateString) ) &&
           (t.endWorkDate == ""   || this.tqDT.isSameDateOrAfter(t.endWorkDate, this.todopadDateString)) )
      {
        if (TASK.hasRepetitionOnWeekdays(t)  && !TASK.taskRepeatsThisWeekday(t, this.todopadDateTime.weekday) ) continue;
//        if (TASK.hasRepetitionOnWeekdays2(t)  && !TASK.taskRepeatsThisWeekday2(t, this.todopadDateTime.weekday) ) continue;
        if (TASK.hasRepetitionOnNumberDay(t) && !TASK.taskRepeatsThisNumberDay(t, this.todopadDateTime.day)) continue;
        if (TASK.hasRepetitionOnMonths(t)    && !TASK.taskRepeatsThisMonth(t, this.todopadWeekday) ) continue;
      }

      // Accept
      let newTask:any = {...t}; 

      // Add or update task
      let oldTask = res.find(t => t.id === newTask.id);
      let index = res.indexOf(oldTask);
      if (index >= 0)
      {
        res[index]=oldTask
      }
      else
      {
        newTask.start = false;
        newTask.end = false
        newTask.due = false;
        newTask.target = false;

        res.push(newTask)
      }

    }

    return this.orderTasksAfterTime(res);
  }

  belongsToTodoList(t): boolean
  {
    if (t.targetTime && this.tqDT.isSameDate(t.targetDate, this.todopadDateString)) return false;  

    // If there is no time set, and the date is the current date, then it belongs to the todo list
    if (!t.startWorkTime && this.tqDT.isSameDate(t.startWorkDate, this.todopadDateString) ) return true;  
    if (!t.endWorkTime && this.tqDT.isSameDate(t.endWorkDate, this.todopadDateString)) return true;  
    if (!t.dueTime && this.tqDT.isSameDate(t.dueDate, this.todopadDateString)) return true;  
    if (!t.targetTime && this.tqDT.isSameDate(t.targetDate, this.todopadDateString)) return true;  

    // If there is no exeTime set for the date, then it belongs to the to-do list
    if (t.exeTime == null || t.exeTime == "" ) return true;

    return false;
  }


  getTasksOnHold()
  {
    var res: Array<any> = [];

    if (this.tasksState.TQtasks == null) return;

    for (var i = 0; i < this.tasksState.TQtasks.length; i++)
    {
      var t=this.tasksState.TQtasks[i];

      // Ignore tasks not on hold
      if (t.status != "waiting" && t.status != "stopped") continue;

      // Filter by project list
      if (this.appState.projectFilterCode != '')
      {
        if (this.appState.projectFilterList.indexOf(t.project_id) < 0) continue;
      }

      // Ignore filtered tasks
      if (this.filterTask(t)) continue;

      // Protect against null relDate (unneeded)
      if (t.relDate == null) { t.relDate="future" }

      // Ignore tasks ending before today or stating after today 
      if ( (t.endWorkDate != ""   && this.tqDT.isDateBefore(t.endWorkDate, this.todopadDateString)) ||
           (t.startWorkDate != "" && this.tqDT.isDateAfter(t.startWorkDate, this.todopadDateString)) )
      {
        continue;
      }

      // Show all other tasks
      res.push(t);
    }

    return this.orderTasksAfterTime(res);
  }
  
  getTasksPastDate()
  {
    var res: Array<any> = [];

    if (this.tasksState.TQtasks == null) return res;   
        
    for (var i = 0; i < this.tasksState.TQtasks.length; i++)
    {
      var t=this.tasksState.TQtasks[i];

      // Ignore tasks without target or due date
      if (!t.dueDate && !t.targetDate) continue;

      // Filter by project list
      if (this.appState.projectFilterCode != '')
      {
        if (this.appState.projectFilterList.indexOf(t.project_id) < 0) continue;
      }

      if (!this.showAllTasks)
      {
        // Ignore finished tasks
        if (t.status == "canceled" || t.status == "done") continue;
      }

      // Ignore filtered tasks
      if (this.filterTask(t)) continue;

      // Ignore tasks not due or not targeted at this date
      if ((t.dueDate && this.tqDT.isSameDateOrAfter(t.dueDate, this.todopadDateString)) || 
          (t.targetDate && this.tqDT.isSameDateOrAfter(t.targetDate, this.todopadDateString))) 
      {
        continue;
      }

      // Show all other tasks
      res.push(t);
    }

    return this.orderTasks(res)
  }


  // TODO: extract to library
  isUndefinedOngoingTask(t: any)
  {
    //if (t.state == "planning" || t.state == "done" || t.state == "canceled") return false;

    // If no start and end date, then it is an undefined ongoing task
    if (t.startWorkDate == "" && t.endWorkDate == "")
    {
      // except when in doing state
      if (t.status == "doing")  
      {
        return false
      } 

      // except those having work time
      if (t.exeTime != "" && t.exeTime != null)
      {
        return false
      } 

      // except those having a repeting day
      if (t.hasRepetitionOnWeekdays) 
      {
        return false;
      }

      // except those having a recurring date
      // if ( t.rep(t))
      // {
      //   return false;
      // }

      // except those having due date today
      if (this.tqDT.isSameDate(t.dueDate, this.todopadDateString))
      {
        return false
      }
      // or having target date today
      if (this.tqDT.isSameDate(t.targetDate, this.todopadDateString))
      {
        return false
      }

      return true;
    }

    return false;
  }

  orderTasks(tasks)
  {
    var da: string;
    var db: string;

    return tasks.sort((a, b) =>
    {
      // return task ordered by priority
      if (a.priority > b.priority) return 1;
      if (a.priority < b.priority) return -1;

      // then by due date
      da=a.dueDate; if (da == "" || da == null) da=this.tqDT.biggestDate()
      db=b.dueDate; if (db == "" || db == null) db=this.tqDT.biggestDate()
      if (this.tqDT.isDateAfter(da, db)) return 1;
      if (this.tqDT.isDateBefore(da, db))  return -1;
    })
  }

  orderTasksAfterTime(tasks)
  {
    var ea: string;
    var eb: string;
    var da: string;
    var db: string;
    return tasks.sort((a, b) =>
    {
      // Set tasks with exeTime on top
      // Compare as simple strings
      ea=a.exeTime; if (ea == "" || ea == null) ea="ZZ"
      eb=b.exeTime; if (eb == "" || eb == null) eb="ZZ"
      if (ea > eb) return 1;
      if (ea < eb) return -1;

      // Order by priority, first
      if (a.priority > b.priority) return 1;
      if (a.priority < b.priority) return -1;

      // Order by due date, last
      da=a.dueDate; if (da == "" || da == null) da=this.tqDT.biggestDate()
      db=b.dueDate; if (db == "" || db == null) db=this.tqDT.biggestDate()
      if (this.tqDT.isDateAfter(da, db)) return 1;
      if (this.tqDT.isDateBefore(da, db))  return -1;
    })
  }

  /**
   * Returns due-today tasks at top of an already ordered list 
   */
   bubbleUpTodayTasks(tasks: any[], date: string)
   {
    if (tasks.length == 0) return;     

    let da: any;
    let db: any;
    let ta: any;
    let tb: any;
    let xa: any;
    let xb: any;

    tasks.sort((a, b) => {
      da = a.dueDate || "";
      db = b.dueDate || "";
      ta = a.targetDate || "";
      tb = b.targetDate || "";

      xa = da ? da : ta || "" ;
      xb = db ? db : tb || "";

      // Set today-due dates to the top
      if (da==db)
      {
        // Set today-target dates to the top
        if (ta==tb)
        {
          if (a.priority < b.priority) return -1;
          if (a.priority > b.priority) return 1; 

          if (xa == xb) return (+a.id <= +b.id) ? -1 : 1;
          if (xa == "") return 1;
          if (xb == "") return -1;
          if (this.tqDT.isDateBefore(xa, xb))  return -1;
          if (this.tqDT.isDateAfter(xa, xb)) return 1;
    
          return (+a.id <= +b.id) ? -1 : 1;
        }
        else
        {
          if (ta == date) return -1;
          if (tb == date) return 1; 

          if (a.priority < b.priority) return -1;
          if (a.priority > b.priority) return 1; 

          if (xa == xb) return (+a.id <= +b.id) ? -1 : 1;
          if (xa == "") return 1;
          if (xb == "") return -1;
          if (this.tqDT.isDateBefore(xa, xb))  return -1;
          if (this.tqDT.isDateAfter(xa, xb)) return 1;
    
          return (+a.id <= +b.id) ? -1 : 1;
        }
      }
      else
      {
        if (da == date) return -1;
        if (db == date) return 1;   
  
        // Set today-target dates to the top
        if (ta==tb)
        {
          if (a.priority < b.priority) return -1;
          if (a.priority > b.priority) return 1; 

          if (xa == xb) return (+a.id <= +b.id) ? -1 : 1;
          if (xa == "") return 1;
          if (xb == "") return -1;
          if (this.tqDT.isDateBefore(xa, xb))  return -1;
          if (this.tqDT.isDateAfter(xa, xb)) return 1;
    
          return (+a.id <= +b.id) ? -1 : 1;
        }
        else
        {
          if (ta == date) return -1;
          if (tb == date) return 1; 

          if (a.priority < b.priority) return -1;
          if (a.priority > b.priority) return 1; 

          if (xa == xb) return (+a.id <= +b.id) ? -1 : 1;
          if (xa == "") return 1;
          if (xb == "") return -1;
          if (this.tqDT.isDateBefore(xa, xb))  return -1;
          if (this.tqDT.isDateAfter(xa, xb)) return 1;
    
          return (+a.id <= +b.id) ? -1 : 1;
        }
      }
    })

    return tasks
  }
 
  toggleAllTasks()
  {
    this.showAllTasks = ! this.showAllTasks

    this.buildTodopadLists()

    this.samApp.saveStore("TQtodopad.showAllTasks", this.showAllTasks, "session")
  }

  toggleOtherDays()
  {
    this.showOtherDays = ! this.showOtherDays

    this.buildTodopadLists()

    this.samApp.saveStore("TQtodopad.showOtherDays", this.showOtherDays, "session")
  }

  clearTodopadFilter()
  {
    this.todopadFilterRegEx = null;
    this.samApp.deleteStore("TQtodopad.filterRegEx", "session")
    this.samApp.deleteStore("TQtodopad.filter", "session")

    this.buildTodopadLists()

    var elem=document.getElementById("TodopadFilter")
    setTimeout(() =>
    {
      elem.focus();
      elem.scrollIntoView({behavior: 'smooth', inline: 'center', block: 'center'});
    }, 0);
  }

  updateTodopadFilter(newFilter)
  {
    this.todopadFilterRegEx=newFilter

    this.buildTodopadLists()
  }

  filterTask(t: any): boolean
  {
    if (this.todopadFilterRegEx == null) return false

    this.samApp.saveStore("TQtodopad.filter", this.todopadFilter, "session")
    this.samApp.saveStore("TQtodopad.filterRegEx", this.todopadFilterRegEx, "session")

    // Return whether the task title matches the filter
    return (t.title.match(new RegExp(this.todopadFilterRegEx,"i")) == null)? true:false;
  }

  clearProjectFilter()
  {
    this.store.dispatch(APP_ACTIONS.projectFiltered({ id: 0, code:'', color:'', list:[]}))
  }

  showProjectCode(code:string)
  {
    return true; 
    
    // Show codes on aggregated mode
    if (this.appState.projectFilterCode == '')  return true;
    if (this.appState.projectFilterCode != code)  return true;
    return false;
  }

  onTaskMenuCommand(command: string, task: any)
  {
    switch(command)
    {
      case "Log Activity":
        this.editActivity(task);
        break;
      case "Edit":
        this.editTask(task);
        break;
      case "Duplicate":
        this.duplicateTask(task.id);
        break;
      case "planning":
      case "todo":
      case "doing":
      case "waiting":
      case "stopped":
      case "done":
      case "canceled":
        this.updateTask(task.id, "status", command);
        break;
      case "A":
      case "B":
      case "C":
      case "D":
      case "E":
        this.updateTask(task.id, "priority", command);
        break;
      case "not pinned":
      case "current day":
      case "next day":
        this.updateTask(task.id, "relDate", command);
        break;
      case "Select":
        this.store.dispatch(TASKS_ACTIONS.selectTask({id: task.id}))
        break;
      }
  }


  //--- Todopad debugging functions ---//

  dumpTodopadDateTime()
  {
    console.log("---");
    console.log("todopadDateTime", this.todopadDateTime);
    console.log("todopadDateString", this.todopadDateString);
    console.log("todopadDateISOString", this.todopadDateISOString);
    console.log("---");    
  } 

}
