import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { from, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';

import * as TASKS_ACTIONS from './tasks.actions';

import { TQApiService } from 'src/app/services/tqapi.service';

@Injectable()
export class TasksEffects {

  constructor(
    private actions$: Actions,
    private tqAPI: TQApiService
  ) { }

  loadTasksList$ = createEffect(() =>
    this.actions$
      .pipe(
        ofType(TASKS_ACTIONS.loadTasksList),
          switchMap(() => 
            from(this.tqAPI.getTaskSamplesByProfile())
              .pipe(
                map(TQtasks => 
                  TQtasks ? TASKS_ACTIONS.loadTasksListOK({ TQtasks }) : TASKS_ACTIONS.loadTasksListSAME()),
                catchError(error => 
                  of(TASKS_ACTIONS.loadTasksListKO({ error })))
              ))
          ))

}