import { TaskSample } from 'src/app/models/task';


export interface TasksState 
{
  status: string; // 'empty' | 'loading' | 'loaded' | 'error'
  error: string;
  
  TQtasks: Array<TaskSample>;
  selectedTaskId: number;
}

export const initialTasksState: TasksState = {
  status: 'empty',
  error: null,

  TQtasks: [],
  selectedTaskId: 0,
}