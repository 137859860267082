import { Project, ProjectSample } from "src/app/models/project";

export interface ProjectsState 
{
  status: string; // 'empty' | 'loading' | 'loaded' | 'error'
  error: string;

  TQprojects: Array<ProjectSample>;
  selectedProjectId: number;

}

export const initialProjectsState: ProjectsState = {
  status: 'empty',
  error: null,

  TQprojects: [],
  selectedProjectId: 0,
  
}