<div class="samPane">
  <div class="samPaneHeader">
    <h4 class="samPaneTitle">
      Project
      <a href="https://docs.taskquark.com/projects.html" target="TQ-docs">
        <fa-icon [icon]="faQuestionCircle" class="TQ-help-icon" title="Help page"></fa-icon>
      </a>
    </h4>
  </div>

  <div *ngIf="isDefaultProject()"
    class="alert alert-info" role="alert">
    This is your default project! You can rename and reuse it, but you cannot delete it.
  </div>

  <div *ngIf="!canAddProject"
    class="alert alert-danger" role="alert">
    You have reached the maximum number of projects for your subscription plan.
  </div>

  <div class="ml-2 mr-2 mt-3">

    <div>
      <label for="ParentProject"
        class="TQ-checkbox-label mt-2 ml-2 mr-2">
        Parent:
      </label>
      <p-treeSelect 
        [options]="projectsNodes"
        [(ngModel)]="parentProjectNode"
        (onNodeSelect)="parentChanged($event)"
      ></p-treeSelect>
      <label for="Role" 
        class="TQ-checkbox-label mt-1 ml-3 mr-1">
        Role:
      </label>
      <tq-role-selector *ngIf="projectParentId == 0 && !isDefaultProject()"
        [(roleId)]="projectRoleId"
      ></tq-role-selector>
      <span *ngIf="projectParentId != 0 || isDefaultProject()">
        {{this.projectRoleCode}}
      </span>
    </div>

    <label for="ProjectCode"
      class="TQ-input-text mt-3 ml-2 mr-2">
      Code:
    </label>
    <input id="ProjectCode" type="text"
      class="mt-3 pl-1 pr-1 samMandatory"
      [ngClass]="'TQ-'+this.getProjectColor()"
      placeholder="Enter a project code..." size="25"
      [(ngModel)]="projectCode">

    <label for="ProjectColor"
      class="TQ-input-text ml-3 mr-1">
      Color:
    </label>
    <div class="mb-2" style="display:inline-block">
      <button class="badge TQ-badge ml-1 TQ-color-button TQ-white"
      (click)="setProjectColor('white')">&nbsp;&nbsp;</button>
      <button class="badge TQ-badge ml-1 TQ-color-button TQ-red"
      (click)="setProjectColor('red')">&nbsp;&nbsp;</button>
      <button class="badge TQ-badge ml-1 TQ-color-button TQ-orange"
      (click)="setProjectColor('orange')">&nbsp;&nbsp;</button>
      <button class="badge TQ-badge ml-1 TQ-color-button TQ-yellow"
      (click)="setProjectColor('yellow')">&nbsp;&nbsp;</button>
      <button class="badge TQ-badge ml-1 TQ-color-button TQ-green"
      (click)="setProjectColor('green')">&nbsp;&nbsp;</button>
      <button class="badge TQ-badge ml-1 TQ-color-button TQ-blue"
      (click)="setProjectColor('blue')">&nbsp;&nbsp;</button>
      <button class="badge TQ-badge ml-1 TQ-color-button TQ-brown"
      (click)="setProjectColor('brown')">&nbsp;&nbsp;</button>
      <button class="badge TQ-badge ml-1 TQ-color-button TQ-purple"
      (click)="setProjectColor('purple')">&nbsp;&nbsp;</button>
      <button class="badge TQ-badge ml-1 TQ-color-button TQ-black"
      (click)="setProjectColor('black')">&nbsp;&nbsp;</button>
    </div>

    <br>

    <label for="ProjectTitle"
      class="TQ-checkbox-label mt-2 ml-2 mr-2">
      Title:
    </label>
    <input id="ProjectTitle" type="Text"
      class="mt-2 pl-1 pr-1 samMandatory"
      [ngClass]="{'col-sm-12' : this.samApp.onMobile,
                  'col-11'    : !this.samApp.onMobile}"
      placeholder="Enter a project title..." maxlength=255
      [(ngModel)]="projectTitle">
    <button title="Save (Ctrl-S)"
      class="btn btn-sm btn-success mt-1 mb-1 ml-1 pa-1"
      [disabled]="clicked || !canAddProject"
      (click)="clicked=true;addProject()">
      {{ !this.projectId ? "Add" : "Save"  }}
    </button>

    <mat-tab-group 
      class="mt-3"
      [mat-stretch-tabs]=false
      [(selectedIndex)]="selectedTab">

      <!-- DESCRIPTION TAB -->

      <mat-tab label="Description">
        <p-editor #TaskDescription 
          [(ngModel)]="projectDescription" 
          [style]="{'white-space':'pre'}"
          placeholder="Enter a description for the project here..."
          ngDefaultControl>
        </p-editor>
      </mat-tab>

      <!-- ACTIVITY TAB -->

      <mat-tab label="Activity"
        *ngIf="samApp.getFeatures().allowActivityLog"
      >
        <label class="TQ-checkbox-label mt-3 ml-3">Project and Tasks Activity Summary:</label>

        <div>
          <label for="ActivityReportStartDate" 
            class="TQ-checkbox-label mt-3 ml-4 mr-1">
            From date:
          </label>
          <tq-date-widget 
            [(date)]="activityReportStartDate"
            [maxDate]="activityReportEndDate"
            (dateChange)="updateActivityReportStartDate()"
          ></tq-date-widget>
          <button 
            class="badge TQ-clear-button ml-1" 
            [disabled]="clicked" 
            (click)="clearActivityReportStartDate()">
            X
          </button>

          <label for="ActivityReportEndDate" 
            class="TQ-checkbox-label mt-3 ml-4 mr-1">
            to:
          </label>
          <tq-date-widget 
            [(date)]="activityReportEndDate"
            [minDate]="activityReportStartDate"
            (dateChange)="updateActivityReportEndDate()"
          ></tq-date-widget>
          <button 
            class="badge TQ-clear-button ml-1" 
            [disabled]="clicked" 
            (click)="clearActivityReportEndDate()">
            X
          </button>
        </div>

        <mat-card class="m-2">
          <mat-card-content>
            <div class="row m-1">
              <div class="ml-1"><b>Number of entries: </b>{{activitiesNumber}}</div>
              <div class="ml-4"><b>Total duration: </b>{{tqDT.minutesToDuration(activitiesTotalDuration)}}</div>
            </div>

            <p-table *ngIf="activities && activities.length > 0"
                [value]="activities" 
                rowGroupMode="rowspan" groupRowsBy="startDate"
                styleClass="p-datatable-gridlines"
                [paginator]="true"
                [rows]="20"
                [rowsPerPageOptions]="[20, 50]"
                >
              <ng-template pTemplate="header">
                  <tr>
                    <th class="p-2" style="width: 110px; text-align:center;"
                      pSortableColumn="startDate">
                      Date
                      <p-sortIcon field="startDate"></p-sortIcon>
                    </th>
                    <th class="p-2" style="width: 70px">Entries</th>
                    <th class="p-2" style="width: 75px">Duration</th>
                    <th class="p-2" >Task</th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-act let-rowgroup="rowgroup" let-rowspan="rowspan">
                  <tr>
                    <td class="p-1" style="text-align:center; vertical-align:top;" 
                      *ngIf="rowgroup" [attr.rowspan]="rowspan">
                      {{ tqDT.formatDateFromISO(act.startDate) }}
                    </td>
                    <td class="p-1">
                      {{ act.numAct }}
                    </td>
                    <td class="p-1">
                      <span *ngIf="act.sumValue && act.sumValue !== 0">{{ tqDT.minutesToDuration(act.sumValue) }}</span>
                    </td>
                    <td class="p-1">
                      <span *ngIf="!act.description"><i>Project activity</i></span>
                      <span *ngIf="act.description">{{ act.description }}</span>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
          </mat-card-content>
        </mat-card>

        <div class="mt-2">
          <button id="LogActivityButton" 
            *ngIf="this.projectId != 0" 
            title="Log Activity" 
            class="btn btn-info"
            [ngClass]="{'ml-3' : !this.samApp.onMobile, 'mt-3 ml-2' : this.samApp.onMobile}"
            [disabled]="!canAddProject" 
            (click)="clicked=true; editProjectActivity()">
            Log Activity
          </button>
          <button id="ReportActivityButton" 
            *ngIf="this.projectId != 0" 
            title="Project Activity Report" 
            class="btn btn-info"
            [ngClass]="{'ml-3' : !this.samApp.onMobile, 'mt-3 ml-2' : this.samApp.onMobile}"
            [disabled]="!canAddProject" 
            (click)="clicked=true; reportActivityPDF()">
            Project Report
          </button>
        </div>
      
      </mat-tab>

    </mat-tab-group>

    <hr/>
    <div *ngIf="this.selectedTab != 1"
      class="mt-1 mb-2"
    >
      <button id="ProjectSaveButton" 
        title="Save (Ctrl-S)"
        class="btn btn-success ml-2"
        [disabled]="projectId == 0 && (clicked || !canAddProject)"
        (click)="clicked=true;addProject()">
        {{ projectId == 0 ? "Add" : "Save"  }}
      </button>
      <button id="ProjectCancelButton" 
        title="Cancel (ESC)"
        class="btn btn-warning ml-1"
        [disabled]="clicked"
        (click)="clicked=true; cancelProject()">
        Cancel
      </button>
      <button id="ProjectDuplicateButton" 
        *ngIf="this.projectId != 0"
        title="Duplicate (Ctrl-D)"
        class="btn btn-success ml-3"
        [disabled]="clicked || !canAddProject"
        (click)="clicked=true;
        duplicateProject()">
        Duplicate
      </button>
      <button 
        *ngIf="this.projectId != 0 && !isDefaultProject()"
        [disabled]="clicked"
        class="btn btn-danger ml-2 mr-2" style="float:right"
        (click)="clicked=true; confirmDelete();">
        Delete
      </button>
    </div>

  </div>

  <p-confirmDialog #cd
    [style]="{width: '33vw'}" 
    [breakpoints]="{'960px': '75vw', '640px': '95vw'}"
    [baseZIndex]="10000"
    focusTrap="true"
    closeOnEscape="true"
    message="
    <b>All tasks and notes in this project and its subprojects will be deleted too.</b>
    <hr>
    Are you sure you want to delete the project?
    ">
    <ng-template pTemplate="header">
      <h3>Delete Project</h3>
    </ng-template>
    <ng-template pTemplate="message" let-message>
      <div class="flex flex-column align-items-center w-full gap-3 border-bottom-1 surface-border">
        <div *ngIf="projectOrigin=='googleCalendar'">
          <span style="color:green">
            <b><i class="pi pi-info-circle mr-2"></i></b>
            The external events in your Google Calendar will NOT be deleted.
          </span>
          <br>
          <span style="color:red">
            <b><i class="pi pi-exclamation-triangle mr-2"></i></b>
            The tasks, notes and any activity logged in this project will be deleted.
          </span>
          <hr>
        </div>
        Are you sure you want to delete the project?
      </div>
    </ng-template>
    <ng-template pTemplate="footer">
        <button type="button" class="btn btn-warning ml-2" (click)="cd.reject()">Cancel</button>
        <button type="button" class="btn btn-danger ml-2" (click)="cd.accept()">Delete</button>
    </ng-template>
  </p-confirmDialog>

</div>
