import { Component, HostListener, OnInit, OnDestroy } from '@angular/core';
import { Router } from "@angular/router";

import { CdkDragDrop, CdkDragStart } from '@angular/cdk/drag-drop';
import { TreeNode } from 'primeng/api';

import { Store } from '@ngrx/store';
import { AppState } from 'src/app/appState/app.state';
import * as APP_ACTIONS from 'src/app/appState/app.actions';
import { appState } from 'src/app/appState/app.selectors';
import { ProjectsState } from 'src/app/projects/store/projects.state';
import * as PROJECTS_ACTIONS from 'src/app/projects/store/projects.actions';
import { projectsState } from 'src/app/projects/store/projects.selectors';

import { SamuraiService } from 'src/services/samurai/samurai.service';
import { TQApiService } from 'src/app/services/tqapi.service';
import { TQSessionService } from 'src/app/services/tqsession.service';

import { faArrowUp, faFilter, faPlus, faRedo, faSpinner, faThumbtack, } from '@fortawesome/free-solid-svg-icons';
import { faCalendarPlus, faFile, faQuestionCircle } from '@fortawesome/free-regular-svg-icons';

@Component({
  selector: 'app-projectpad',
  templateUrl: './projectpad.component.html',
  styleUrls: ['./projectpad.component.scss'],
})
export class ProjectpadComponent implements OnInit, OnDestroy
{
  faArrowUp = faArrowUp;
  faCalendarPlus = faCalendarPlus;
  faFile = faFile;
  faFilter = faFilter;
  faPlus = faPlus;
  faQuestionCircle = faQuestionCircle;
  faRedo = faRedo;
  faSpinner = faSpinner;
  faThumbtack = faThumbtack;

  componentState: string = 'init';

  appState: AppState;
  appStateSubs: any;

  projectsState: ProjectsState;
  projectsStateSubs: any;

  canAddProject: boolean = true;

  projectId: number = 0; // Local copy of projectsState.selectedProjectId

  projectpadTree: TreeNode[] = [];

  projectpadFilter: boolean = false;
  projectpadFilterRegEx: string  = null ;

  allowProjectImportation = false;

  constructor(
    private router: Router,
    private store: Store,
    private tqApi: TQApiService,
    private tqSession: TQSessionService,
    private samApp: SamuraiService,
  )
  {}

  async ngOnInit()
  {
    this.store.dispatch(APP_ACTIONS.setBackURL({ url:this.router.url }))    
    this.store.dispatch(APP_ACTIONS.enterTQpane( {pane:'projectpad'} ))

    this.appStateSubs = this.store.select(appState)
      .subscribe( state => {
        this.appState = state 
        if (state.status != 'loaded') return;
        if (this.appState.activePane != 'projectpad') return;

        if (this.componentState == 'loaded')
        {
          this.canAddProject = this.tqSession.canAddProject();
        
          this.buildProjectpad();          
        }
      })

    this.projectsStateSubs = this.store.select(projectsState)
      .subscribe( state => {
        this.projectsState = state 
        if (state.status != 'loaded') return;
        if (this.appState.activePane != 'projectpad') return;

        if (this.componentState == 'loaded')
        {
          this.canAddProject = this.tqSession.canAddProject();

          this.projectId=state.selectedProjectId;

          this.buildProjectpad();          
        }
      })

    // Check integrations feature flag
    this.allowProjectImportation = this.samApp.getFeatures().GOOGLE_calendar_import
    // Superseed with the global feature flag
    if (this.samApp.getFeatures().allowIntegrations == false) 
    {
      this.allowProjectImportation = false;
    }
      
    this.projectpadFilter=this.samApp.retrieveStore("TQprojectpad.filter", "session")
    this.projectpadFilterRegEx=this.samApp.retrieveStore("TQprojectpad.filterRegEx", "session")

    if (this.tqSession.getStatus() == 'loaded')  
    {
      this.store.dispatch(PROJECTS_ACTIONS.loadProjectsList());
    }
      
    this.componentState = 'loaded';
  }

  ngOnDestroy()
  {
    this.appStateSubs.unsubscribe();
    this.projectsStateSubs.unsubscribe();
  }

  @HostListener('document:keydown.control.f', ['$event'])
  CtrlF(event: any)
  {
    event.preventDefault();
    this.clearProjectpadFilter();
    this.projectpadFilter = true;

    this.samApp.saveStore("TQprojectpad.filter", this.projectpadFilter, "session")
    this.samApp.saveStore("TQprojectpad.filterRegEx", this.projectpadFilterRegEx, "session")
  }
  @HostListener('document:keydown', ['$event'])
  KeyDownOnFilter(event: any)
  {
    if (this.projectpadFilter == false) return;

    /// Force Notepad update after processing the key
    setTimeout( () => { this.buildProjectpad(); },0)

    this.samApp.saveStore("TQprojectpad.filter", this.projectpadFilter, "session")
    this.samApp.saveStore("TQprojectpad.filterRegEx", this.projectpadFilterRegEx, "session")
  }
  @HostListener('document:keydown.escape', ['$event'])
  Escape(event: any)
  {
    event.preventDefault();
    if (this.projectpadFilter == true)
    {
      this.clearProjectpadFilter();
      this.projectpadFilter = false;

      this.samApp.saveStore("TQprojectpad.filter", this.projectpadFilter, "session")
      this.samApp.saveStore("TQprojectpad.filterRegEx", this.projectpadFilterRegEx, "session")
    }
    else if (this.projectId == 0)
    {
      this.clearProjectpadFilter()
    }
    else     
    {
      this.clearToTop()
    }
  }
  
  dragStarted(event: CdkDragStart, node: TreeNode)
  {
    function enableSibblings(tree, parent)
    {
      tree.forEach(p => {
        p.data.droppable = true;
        if (p.data.parent_project_id != parent) 
        {
          p.data.droppable = false;
        }
        enableSibblings(p.children, parent);
      })
    } 

    let parent = event.source.data[0].parent_project_id
    enableSibblings(this.projectpadTree, parent)
  }
 
  drop(event: CdkDragDrop<string[]>)
  {
    function flattenTreeDFS(tree) {
      let result = [];
      function traverse(node) 
      {
        result.push(node); 
        if (node.children && node.children.length > 0) 
          {
          node.children.forEach(child => traverse(child)); 
        }
      }
      tree.forEach(rootNode => traverse(rootNode)); 
      return result;
    }

    // Flatten the tree using DFS
    let flattenedTreeDFS = flattenTreeDFS(this.projectpadTree);

    let id          = flattenedTreeDFS[event.previousIndex].data.id
    let origin      = flattenedTreeDFS[event.previousIndex].data.ord
    let oldParent   = flattenedTreeDFS[event.previousIndex].data.parent_project_id
    let destination = flattenedTreeDFS[event.currentIndex].data.ord
    let newParent   = flattenedTreeDFS[event.currentIndex].data.parent_project_id

    // Remove drop marks 
    this.projectpadTree.forEach(p => {p.data.droppable = false;})

    // Update if same parent
    if (newParent == oldParent) 
    {
      this.updateProjectOrder(id, newParent, origin, destination);
    }

    this.store.dispatch(PROJECTS_ACTIONS.selectProject({id: id}))
  }

  scrollToPaneTitle()
  {
    document.getElementById("projectpadPane").scrollIntoView({ behavior: 'smooth' });
  }
  
  clearToTop()
  {
    this.store.dispatch(PROJECTS_ACTIONS.selectProject({id: 0}))   
    this.scrollToPaneTitle()
  }

  scrollToProject()
  {
    // Do not scroll when filter is active
    if (this.projectpadFilter == true) return;

    // No projects where to scroll
    if (this.projectId == null) return;

    // Search in Col1
    var elem = document.getElementById("col1-"+this.projectId.toString());
    if (elem != null)
    {
      elem.scrollIntoView({inline: 'start', block: 'center'});
    }
  }

  editProject(t?: any)
  {
    if (t == null)
    {
      this.projectId=0;
    }
    else
    {
      this.projectId=t["id"];
    }
    // Leaving component
    this.store.dispatch(APP_ACTIONS.activateTQpane({ pane:'project' })) 

    this.store.dispatch(PROJECTS_ACTIONS.selectProject({id: this.projectId}))

    this.samApp.saveStore("TQprojectpad.filter", this.projectpadFilter, "session")
    this.samApp.saveStore("TQprojectpad.filterRegEx", this.projectpadFilterRegEx, "session")

    this.router.navigate(['/project', this.projectId])
  }

  async updateProjectOrder
  (
    id: number,
    newParent: number,
    origin: number,
    destination: number
  )
  {
    var TQProject = {
      "id"                : id,
      "profile_id"        : this.appState.TQprofileId,
      "origin"            : origin,
      "destination"       : destination,
    }
    await this.tqApi.updateProjectOrder(TQProject)
       
    this.store.dispatch(PROJECTS_ACTIONS.loadProjectsList());
  }

  buildProjectpad()
  {
    // this.cdr.detach();
    this.projectpadTree = this.buildProjectpadTree();

    // setTimeout( () => this.scrollToProject(), 0)

    // this.cdr.reattach();
  }

  buildProjectpadTree()
  {
    let tree:TreeNode[] = [];
    let node:TreeNode;
    
    // Load all projects first, because they are not ordered
    // and any child may appear before the parent
    let projects = this.getTQProjects();
    projects.forEach(p => {
      node = {
        data: {
          id: p.id,
          parent_project_id: p.parent_project_id,
          code: p.code,
          color: p.color,
          title: p.title,
          role_id: p.role_id,
          role_code: this.tqSession.getTQRole(p.role_id).code, // p.parent_project_id ? "" : this.tqSession.getTQRole(p.role_id).code,
          ord: p.ord,
          droppable: true,
        },
        expanded: true,
        children: [] // One level list of children, not a tree
      }  
      tree.push(node)
    })
    // Keep a copy of the children for each parent, if exist
    tree.filter(p => p.data.parent_project_id != null)
      .forEach(p => {
        // Add to parent's children
        let parent = tree.filter(n => n.data.id == p.data.parent_project_id)
        parent[0].children.push(p)  
    })

    // Filter by project selected
    let res = []
    if (this.appState.projectFilterId == 0)
    {
      // Return top level parents
      res = tree.filter(p => p.data.parent_project_id == null);
    }
    else 
    {
      // Get only the selected project 
      res = tree.filter(p => p.data.id == this.appState.projectFilterId);
    }

    // Filter leaves by projectpad filter
    res.forEach(p => {
      this.filterNode(res, null, p)
    })

    // Filter again the final selection
    res.forEach(p => {
      if (p.children.length == 0)
      {
        if (this.filterProject(p.data)) res = res.filter(n => n.data.id != p.data.id)
      }
    }) 

    setTimeout( () => this.scrollToProject(), 0)

    return res
  }

  filterNode(res, parent, node)
  {
    if (node.children.length == 0)
    {
      if (this.filterProject(node.data)) {
        if (parent != null)
        {
          parent.children = parent.children.filter(c => c.data.id != node.data.id)
        }
      }
    }
    else
    {
      node.children.forEach(c => {
        this.filterNode(res, node, c)
        // When no children left, check filter on node
        if (node.children.length == 0)
        {
          if (this.filterProject(node.data)) 
          {
            if (parent?.children.length > 0) parent.children = parent.children.filter(n => n.data.id != node.data.id)
          }
        }
      })
    }
  }

  getTQProjects()
  {
    var res: Array<any> = [];

    let projects = this.projectsState.TQprojects

    for (var i = 0; i < projects.length; i++)
    {
      var p=projects[i];

      // Ignore filtered projects
///      if (this.filterProject(p)) continue;

      res.push(p)
    }

    return res;
  }

  importProject()
  {
    this.router.navigate(['/project-importer']) 
  }

  clearProjectpadFilter()
  {
    this.projectpadFilterRegEx = null;
    this.samApp.deleteStore("TQprojectpad.filterRegEx", "session")
    this.samApp.deleteStore("TQprojectpad.filter", "session")

    this.buildProjectpad();

    var elem=document.getElementById("ProjectpadFilter")
    setTimeout(() =>
    {
      elem.focus();
      elem.scrollIntoView({behavior: 'smooth', inline: 'center', block: 'center'});
    }, 100);
  }

  filterProject(p: any): boolean
  {
    if (this.projectpadFilterRegEx == null) return false

    this.samApp.saveStore("TQprojectpad.filter", this.projectpadFilter, "session")
    this.samApp.saveStore("TQprojectpad.filterRegEx", this.projectpadFilterRegEx, "session")

    // Return whether the project title matches the filter
    return (p['title'].match(new RegExp(this.projectpadFilterRegEx,"i")) == null)? true:false;
  }

  clearProjectFilter()
  {
    this.store.dispatch(APP_ACTIONS.projectFiltered({ id: 0, code:'', color:'', list:[]}))
  }

}
