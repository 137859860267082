<span 
  class="badge TQ-badge"
  [ngStyle]="{'background-color':this.getPriorityColor(t.priority)}" >
  {{t.priority==null?'&nbsp;&nbsp;':t.priority}}
</span>
<span *ngIf="showProjectCode" 
  class="badge TQ-badge ml-1"
  [ngClass]="'TQ-'+t.project_color"
  title="{{t.project_title}}">
  {{t.project_code}}
</span>
<span 
  [ngClass]="'task-title task-' + t.status" 
  class="text-break">
  {{t.title}}
</span>
<span *ngIf="t.start" class="task-icon ml-1">
  <fa-icon [icon]="faCaretSquareRight" title="start date"></fa-icon>
</span>
<span *ngIf="t.end" class="task-icon ml-1">
  <fa-icon [icon]="faCaretSquareLeft" title="end date"></fa-icon>
</span>
<span *ngIf="t.target" class="task-icon ml-1" style="color:blue">
  <fa-icon [icon]="faCaretSquareDown" title="target date"></fa-icon>
</span>
<span *ngIf="showDuration && t.status=='doing' && t.exeDuration" 
  class="ml-1 mr-1 text-break">
  <b>+{{tqDT.shortDuration(t.exeDuration)}}</b>
</span>
<span *ngIf="t.due" class="task-icon ml-1" style="color:red">
  <fa-icon [icon]="faCaretSquareDown" title="due date"></fa-icon>
</span>
<span *ngIf="(t.dueDate)" class="task-date right ml-1">
  <tq-task-event-date [eventType]="'due'" [date]="t.dueDate" [onDate]="onDate"></tq-task-event-date>
</span>
<span *ngIf="(t.targetDate)" class="task-date right ml-1">
  <tq-task-event-date [eventType]="'target'" [date]="t.targetDate" [onDate]="onDate"></tq-task-event-date>
</span>
<span *ngIf="(t.relDate=='+0d')"
  class="task-icon right ml-1">
  <fa-icon [icon]="faThumbtack" title="Current day"></fa-icon>
</span>
<span *ngIf="(t.relDate=='+1d')"
  class="task-icon right ml-1">
  <fa-icon [icon]="faThumbtack" title="Next day"></fa-icon>
</span>
<span *ngIf="isRepeatingTask(t)"
  class="task-icon right ml-1">
  <fa-icon [icon]="faRedo" title="Recurring task"></fa-icon>
</span>
<span *ngIf="(t.hasDescription)"
  class="task-icon right ml-1">
  <fa-icon [icon]="faFile" title="Note"></fa-icon>
</span>
<span *ngIf="(t.activityCount>0)"
  class="task-icon right ml-1">
  <fa-icon [icon]="faClock" title="Activity"></fa-icon>
</span>
<span *ngIf="(t.origin)"
  class="task-icon right ml-1">
  <fa-icon [icon]="faCalendarCheck" title="External Event"></fa-icon>
</span>
