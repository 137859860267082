import { Injectable } from '@angular/core';

import { SamuraiService } from 'src/services/samurai/samurai.service';
import { TQApiService } from 'src/app/services/tqapi.service';


// "google.accounts.oauth2.CodeClient"
interface CodeClient 
{
  requestCode(): void;
}

// This is a workaround to avoid TypeScript errors when using the Google Calendar API client
declare var google: any;


@Injectable({
  providedIn: 'root'
})
export class CalendarService 
{

  constructor
  (
    private samApp: SamuraiService,
    private tqApi: TQApiService,
  ) 
  {}
  

  /**
   * Set up the Google Calendar API client with a callback function
   * @param callback 
   * @returns client
   */
  async setGoogleCalendarClientCallback(
    callback: (response: { code: string }) => void
  ): Promise<CodeClient> 
  {
    // Get the Google Calendar API client credentials
    const gCalendarAPIClientCredentials = await this.tqApi.getGoogleCalendarAPIClient()
    
    return google.accounts.oauth2.initCodeClient({
      client_id: gCalendarAPIClientCredentials["clientId"],
      scope: "https://www.googleapis.com/auth/calendar.readonly \
              https://www.googleapis.com/auth/calendar.events ",
      ux_mode: "popup", 
      // prompt: 'force', // Force the user to select the account
      access_type: 'offline',
      callback
    });
  }

  /**
   * Trigger the Google Calendar API client to request the auth code
   * @param client
   */
  // TODO Using "any" instead of"google.accounts.oauth2.CodeClient"
  async connectGoogleCalendar(client: CodeClient): Promise<void> 
  {
    client.requestCode();
  }

  /**
   * Reconnect to Google Calendar with new auth code
   * Uses the failed request to determine the task being updated
   */
  async reconnectGoogleCalendar(request: any)
  {
    const googleCalendar = await this.setGoogleCalendarClientCallback(
      async (response) => {
        this.samApp.trace("Google Calendar reconnect with Auth Code : " + response.code);

        // Current profile

        var TQProject = {
          "auth_code" : response.code,
          "task_id"   : request.id,
        }
        this.samApp.trace("Updating TQProject : " + JSON.stringify(TQProject));
        await this.tqApi.updateProjectAuthCodeByTaskId(TQProject)         
      })

    this.connectGoogleCalendar(googleCalendar);
  }
}
