<div class="samPane">
  <div class="samPaneHeader">
    <h4 class="samPaneTitle">Import a Project</h4>
  </div>

  <div class="p-3 ml-2 mr-2">
    <h5>
      Import from a Google Calendar
      <a href="https://docs.taskquark.com/import-google-calendar.html" target="TQ-docs">
        <fa-icon [icon]="faQuestionCircle" class="TQ-help-icon" title="Help page"></fa-icon>
      </a>
    </h5>

    <div 
      class="mt-3 ml-2">
      Click the button below grant to TaskQuark permissions to access your Google calendars 
      <br>
      <button
        class="btn btn-success"
        (click)="getGoogleCalendars()">
        List my Google Calendars
      </button>
    </div>

    <div *ngIf="googleCalendarsList"
      class="mt-3 ml-2 mr-2">
      Select which of the following Google Calendars will be imported as a TaskQuark project:
      <table 
        class="table table-bordered table-striped mt-3">
        <thead>
          <tr>
            <td class="col-1 w-10 p-1"><b>Select</b></td> 
            <td class="col-2 w-20 p-1"><b>Name</b></td>
            <td class="col-1 w-10 p-1"><b>Access Role</b></td>
            <td class="col-8 w-80 p-1"><b>Description</b></td>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let calendar of googleCalendarsList"
            class="m-0 p-0">
            <td class="p-1">
              <input type="radio" 
                name="googleCalendarId" 
                [(ngModel)]="googleCalendarId" 
                value="{{calendar['id']}}">
            </td>
            <td class="p-1">{{calendar['summary']}}</td>
            <td class="p-1">{{calendar['accessRole']}}</td>
            <td class="p-1">{{calendar['description']}}</td>
          </tr>
        </tbody>
      </table>
      <div style="display:inline-block">
        <input 
          type="checkbox" 
          ng-checked="googleCalendarSync"
          [(ngModel)]="googleCalendarSync">
        <label 
          class="TQ-checkbox-label ml-2 mr-1">
          Keep the new TaskQuark project connected to the selected Google Calendar. <br>
          <i>(Uncheck this option to just import a copy of the calendar events once)</i>
        </label>
      </div>
  </div>

  <hr/>

  <div class="mt-1 mb-2">
    <button id="ImportButton" 
      title="Import"
      class="btn btn-success ml-2"        
      [disabled]="!googleCalendarId"
      (click)="importGoogleCalendar()">
      Import
    </button>
    <button id="ImportCancelButton" 
      title="Cancel (ESC)"
      class="btn btn-warning ml-1"
      (click)="cancelImport()">
      Cancel
    </button>
  </div>

</div>



