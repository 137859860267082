import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';

import { Observable, throwError, NEVER } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { Store } from '@ngrx/store';
import { AppState } from 'src/app/appState/app.state';
import { appState } from 'src/app/appState/app.selectors';

import { Note, NoteSample } from 'src/app/models/note';
import { Profile } from 'src/app/models/profile';
import { Task, TaskSample } from 'src/app/models/task';

import { SamuraiService } from 'src/services/samurai/samurai.service';
import { TQDateTimeService } from 'src/app/services/tqdatetime.service';
import { CalendarService } from './google/calendar.service';


/*************************
 * TQAQPI Service
 *************************/

@Injectable({
  providedIn: 'root'
})
export class TQApiService
{
  appState: AppState;
  appStateSubs: any;

  private apiError: string;

  private TQWEB_led="red";
  private TQAPI_led="red";
  private TQDB_led="red";

  private apiTQDBtimestamps: Map<string, string> = new Map();
  private apiTQDBtimestampClock: number = 5;
  private apiTQDBtimestampDelay: number = 5;


  constructor
  (
    private http: HttpClient,
    private store: Store,
    private samApp: SamuraiService,
    private tqDT: TQDateTimeService,
  ) 
  {
    this.samApp.debug("TQAPI loading...")

    this.appStateSubs = this.store.select(appState)
      .subscribe( state => { 
          this.appState = state
        }
    )

    // Initialize timestamps to "0"
    this.setTQDBtimestamp("tasks", "0"); 
  }

  async debug(msg: string)
  {
    if (this.samApp.getDebugLevel() == 'debug' || this.samApp.getDebugLevel() == 'trace')
    {
      this.trace(msg);
    }
  }

  async trace(msg: string)
  {
    if (this.samApp.getDebugLevel() != 'debug' && this.samApp.getDebugLevel() != 'trace') return;

    this.setTQAPI_led("yellow");
    const headers = new HttpHeaders({
      'Content-type': 'text/plain',
    });
    const URL = "/api/tqpings/trace";
    try
    {
      msg = Date.now() + ": " + msg;
      let res = await this.http.post(URL, msg, {headers}).toPromise()

      this.setTQAPI_led("green");
      return res
    }
    catch
    {
      this.setTQAPI_led("red");
      this.apiError="Error on TQAPI trace"
      throw(this.apiError)
    }
  }

  async pingSIGNIN()
  {
    this.setTQAPI_led("yellow");
    const headers = new HttpHeaders({
      'Content-type': 'text/plain'
    });
    const URL = "/api/tqpings/signin";
    try
    {
      var res = await this.http.get(URL, {headers}).toPromise()

      this.setTQAPI_led("green");
      return res
    }
    catch
    {
      this.setTQAPI_led("red");
      throw (null)
    }
  }

  async pingSIGNUP()
  {
    this.setTQAPI_led("yellow");
    const headers = new HttpHeaders({
      'Content-type': 'text/plain'
    });
    const URL = "/api/tqpings/signup";
    try
    {
      var res = await this.http.get(URL, {headers}).toPromise()

      this.setTQAPI_led("green");
      return res
    }
    catch
    {
      this.setTQAPI_led("red");
      throw (null)
    }
  }

  async pingLOADING()
  {
    this.setTQAPI_led("yellow");
    const headers = new HttpHeaders({
      'Content-type': 'text/plain'
    });
    const URL = "/api/tqpings/loading";
    try
    {
      var res = await this.http.get(URL, {headers}).toPromise()

      this.setTQAPI_led("green");
      return res
    }
    catch
    {
      this.setTQAPI_led("red");
      throw (null)
    }
  }

  async pingLOGOUT()
  {
    this.setTQAPI_led("yellow");
    const headers = new HttpHeaders({
      'Content-type': 'text/plain',
      'tqapi_profile_id' : this.appState.TQprofileId,
    });
    const URL = "/api/tqpings/logout";
    try
    {
      var res = await this.http.get(URL, {headers}).toPromise()

      this.setTQAPI_led("green");
      return res
    }
    catch
    {
      this.setTQAPI_led("red");
      throw (null)
    }
  }

  async pingTQAPI()
  {
    this.setTQAPI_led("yellow");
    const headers = new HttpHeaders({
      'Content-type': 'text/plain'
    });
    const URL = "/api/tqpings/tqapi";
    try
    {
      var res = await this.http.get(URL, {headers}).toPromise()

      this.setTQAPI_led("green");
      return res
    }
    catch
    {
      this.setTQAPI_led("red");
      throw (null)
    }
  }

  async pingTQDB()
  {
    this.setTQDB_led("yellow");
    const headers = new HttpHeaders({
      'Content-type': 'text/plain'
    });
    const URL = "/api/tqpings/tqdb";
    try
    {
      var res = await this.http.get(URL, {headers}).toPromise()

      this.setTQDB_led("green");
      return res
    }
    catch
    {
      this.setTQDB_led("red");
      throw (null)
    }
  }

  public getTQWEB_led()
  {
    return this.TQWEB_led;
  }
  public setTQWEB_led(status: string)
  {
    this.TQWEB_led = status;
  }

  public getTQAPI_led()
  {
    return this.TQAPI_led;
  }
  public setTQAPI_led(status: string)
  {
    this.TQAPI_led = status;
  }

  public getTQDB_led()
  {
    return this.TQDB_led;
  }
  public setTQDB_led(status: string)
  {
    this.TQDB_led = status;
  }

  public getTQDBtimestampClock()
  {
    return this.apiTQDBtimestampClock;
  } 
  
  public decTQDBtimestampClock()
  {
    this.apiTQDBtimestampClock -=1;
    if (this.apiTQDBtimestampClock < 1) this.apiTQDBtimestampClock = 1;
    return this.apiTQDBtimestampClock;
  } 
  
  public incTQDBtimestampClock()
  {
    this.apiTQDBtimestampClock = this.apiTQDBtimestampDelay;
    if (this.apiTQDBtimestampClock > 300) this.apiTQDBtimestampClock = 300;
    return this.apiTQDBtimestampClock;
  } 
  public getTQDBtimestampDelay()
  {
    return this.apiTQDBtimestampDelay;
  } 
  
  public decTQDBtimestampDelay()
  {
    this.apiTQDBtimestampDelay -=5;
    if (this.apiTQDBtimestampDelay < 1) this.apiTQDBtimestampDelay = 1;
    return this.apiTQDBtimestampDelay;
  } 
  
  public incTQDBtimestampDelay()
  {
    this.apiTQDBtimestampDelay +=5;
    if (this.apiTQDBtimestampDelay > 3600) this.apiTQDBtimestampDelay = 3600;
    return this.apiTQDBtimestampDelay;
  } 

  public resetTQDBtimestampDelay()
  {
    this.apiTQDBtimestampClock = 5;
    this.apiTQDBtimestampDelay = 5;
  } 


  /*********************************
   GOOGLE CALENDARS
  *********************************/

  async getGoogleCalendarAPIClient()
  {
    this.setTQAPI_led("yellow");
    const headers = new HttpHeaders({
      'Content-type': 'application/json' ,
      'Authorization' : 'Bearer ' + this.appState.Auth0Token,
      'tqapi_profile_id' : this.appState.TQprofileId,
    });
    var URL = "/api/google/calendars/client"; 
    try
    {
      let res = await this.http.get(URL, {headers}).toPromise()

      this.setTQAPI_led("green");
      return res
    }
    catch
    {
      this.setTQAPI_led("red");
      this.apiError="Error on gcalendarAuthCode"
      throw(this.apiError)
    }
  }


  /*********************************
   STRIPE CHECKOUTS
  *********************************/

  async getStripeCheckoutSession(subscription:any)
  {
    this.setTQAPI_led("yellow");
    const headers = new HttpHeaders({
      'Content-type': 'application/json' ,
      'Authorization' : 'Bearer ' + this.appState.Auth0Token,
      'tqapi_profile_id' : this.appState.TQprofileId,
      'subscription_plan' : subscription.subscription_plan,
    });
    var URL = "/stripe/checkout/sessions";
    try
    {
      let res = await this.http.post(URL, subscription, {headers}).toPromise()

      this.setTQAPI_led("green");
      return res
    }
    catch
    {
      this.setTQAPI_led("red");
      this.apiError="Error on getStripeSession"
      throw(this.apiError)
    }
  }


   /*********************************
   ACTIVITIES
  *********************************/

  async getActivitiesByTaskId(taskId: number)
  {
    this.setTQAPI_led("yellow");
    const headers = new HttpHeaders({
      'Content-type': 'application/json' ,
      'Authorization' : 'Bearer ' + this.appState.Auth0Token,
      'tqapi_profile_id' : this.appState.TQprofileId,
    });
    var URL = "/api/activities/task/"+taskId;
    try
    {
      let res = await this.http.get(URL, {headers}).toPromise()
      this.setTQAPI_led("green");
      return res
    }
    catch
    {
      this.setTQAPI_led("red");
      this.apiError="Error on getActivityByTaskId"
      throw(this.apiError)
    }
  }

  async getActivitiesByProjectId(projectId: number)
  {
    this.setTQAPI_led("yellow");
    const headers = new HttpHeaders({
      'Content-type': 'application/json' ,
      'Authorization' : 'Bearer ' + this.appState.Auth0Token,
      'tqapi_profile_id' : this.appState.TQprofileId,
    });
    var URL = "/api/activities/project/"+projectId;
    try
    {
      let res = await this.http.get(URL, {headers}).toPromise()
      this.setTQAPI_led("green");
      return res
    }
    catch
    {
      this.setTQAPI_led("red");
      this.apiError="Error on getActivityByProjectId"
      throw(this.apiError)
    }
  }

  async getActivitySummaryByTaskId(taskId: number, startDate: string, endDate: string)
  {
    this.setTQAPI_led("yellow");
    const headers = new HttpHeaders({
      'Content-type': 'application/json' ,
      'Authorization' : 'Bearer ' + this.appState.Auth0Token,
      'tqapi_profile_id' : this.appState.TQprofileId,
    });

    let from = "";
    if (startDate != null) from="from="+startDate;
    let to = "";
    if (endDate != null) to="&to="+endDate;
    
    var URL = "/api/activities/task/"+taskId+"/summary?"+from+to;
    try
    {
      let res = await this.http.get(URL, {headers}).toPromise()
      this.setTQAPI_led("green");
      return res
    }
    catch
    {
      this.setTQAPI_led("red");
      this.apiError="Error on getActivitySummaryByTasktId"
      throw(this.apiError)
    }
  }

  async getActivitySummaryByProjectId(projectId: number)
  {
    this.setTQAPI_led("yellow");
    const headers = new HttpHeaders({
      'Content-type': 'application/json' ,
      'Authorization' : 'Bearer ' + this.appState.Auth0Token,
      'tqapi_profile_id' : this.appState.TQprofileId,
    });
    var URL = "/api/activities/project/"+projectId+"/summary";
    try
    {
      let res = await this.http.get(URL, {headers}).toPromise()
      this.setTQAPI_led("green");
      return res
    }
    catch
    {
      this.setTQAPI_led("red");
      this.apiError="Error on getActivitySummaryByProjectId"
      throw(this.apiError)
    }
  }

  async getActivityPDFReportByTaskId(taskId: number, startDate: string, endDate: string): Promise<Blob>
  {
    this.setTQAPI_led("yellow");
    const headers = new HttpHeaders({
      'Content-type': 'application/json' ,
      'Authorization' : 'Bearer ' + this.appState.Auth0Token,
      'tqapi_profile_id' : this.appState.TQprofileId,
    });

    let from = "";
    if (startDate != null) from="from="+startDate;
    let to = "";
    if (endDate != null) to="&to="+endDate;
    
    var URL = "/api/activities/task/"+taskId+"/PDFreport?"+from+to;
    try
    {
      let res = await this.http.get(URL, {headers, responseType: 'blob'}).toPromise()
      this.setTQAPI_led("green");
      return res
    }
    catch
    {
      this.setTQAPI_led("red");
      this.apiError="Error on getActivityPDFReportByTaskId"
      throw(this.apiError)
    }
  }

  async getActivityPDFReportByProjectId(projectId: number, startDate: string, endDate: string): Promise<Blob>
  {
    this.setTQAPI_led("yellow");
    const headers = new HttpHeaders({
      'Content-type': 'application/json' ,
      'Authorization' : 'Bearer ' + this.appState.Auth0Token,
      'tqapi_profile_id' : this.appState.TQprofileId,
    });

    let from = "";
    if (startDate != null) from="from="+startDate;
    let to = "";
    if (endDate != null) to="&to="+endDate;
    
    var URL = "/api/activities/project/"+projectId+"/PDFreport?"+from+to;
    try
    {
      let res = await this.http.get(URL, {headers, responseType: 'blob'}).toPromise()
      this.setTQAPI_led("green");
      return res
    }
    catch
    {
      this.setTQAPI_led("red");
      this.apiError="Error on getActivityPDFReportByProjectId"
      throw(this.apiError)
    }
  }

  async postActivity(activity: any)
  {
    this.setTQAPI_led("yellow");
    const headers = new HttpHeaders({
      'Content-type': 'application/json' ,
      'Authorization' : 'Bearer ' + this.appState.Auth0Token,
      'tqapi_profile_id' : this.appState.TQprofileId,
    });
    var URL = "/api/activities/";
    try
    {
      let res = await this.http.post(URL, activity, {headers}).toPromise()

      this.setTQAPI_led("green");
      return res
    }
    catch
    {
      this.setTQAPI_led("red");
      this.apiError="Error on postActivity"
      throw(this.apiError)
    }
  }

  async deleteActivity(id: number)
  {
    this.setTQAPI_led("yellow");
    const headers = new HttpHeaders({
      'Content-type': 'application/json' ,
      'Authorization' : 'Bearer ' + this.appState.Auth0Token,
      'tqapi_profile_id' : this.appState.TQprofileId,
    });
    var URL = "/api/activities/"+id;
    try
    {
      let res = await this.http.delete(URL, {headers}).toPromise()

      this.setTQAPI_led("green");
      return res
    }
    catch
    {
      this.setTQAPI_led("red");
      this.apiError="Error on deleteActivity"
      throw(this.apiError)
    }
  }


  /*********************************
   NOTES
  *********************************/

  async getNote(id: number): Promise<Note>
  {
    this.setTQAPI_led("yellow");
    const headers = new HttpHeaders({
      'Content-type': 'application/json' ,
      'Authorization' : 'Bearer ' + this.appState.Auth0Token,
      'tqapi_profile_id' : this.appState.TQprofileId,
    });
    var URL = "/api/note/"+id;
    try
    {
      var res = await this.http.get(URL, {headers}).toPromise()
      this.setTQAPI_led("green");

      let note: any = res;
      return note
    }
    catch
    {
      this.setTQAPI_led("red");
      this.apiError="Error on getNote"
      throw(this.apiError)
    }
  }

  async postNote(note: any)
  {
    this.setTQAPI_led("yellow");
    const headers = new HttpHeaders({
      'Content-type': 'application/json' ,
      'Authorization' : 'Bearer ' + this.appState.Auth0Token,
      'tqapi_profile_id' : this.appState.TQprofileId,
    });
    var URL = "/api/notes/";
    try
    {
      let res = await this.http.post(URL, note, {headers}).toPromise()

      this.setTQAPI_led("green");
      return res
    }
    catch
    {
      this.setTQAPI_led("red");
      this.apiError="Error on postNote"
      throw(this.apiError)
    }
  }

  async deleteNote(id: number)
  {
    this.setTQAPI_led("yellow");
    const headers = new HttpHeaders({
      'Content-type': 'application/json' ,
      'Authorization' : 'Bearer ' + this.appState.Auth0Token,
      'tqapi_profile_id' : this.appState.TQprofileId,
    });
    var URL = "/api/notes/"+id;
    try
    {
      await this.http.delete(URL, {headers}).toPromise()

      this.setTQAPI_led("green");
      return 
    }
    catch
    {
      this.setTQAPI_led("red");
      this.apiError="Error on deleteNote"
      throw(this.apiError)
    }
  }

  async getNotesByProfile(): Promise<any>
  {
    this.setTQAPI_led("yellow");
    const headers = new HttpHeaders({
      'Content-type': 'application/json' ,
      'Authorization' : 'Bearer ' + this.appState.Auth0Token,
      'tqapi_profile_id' : this.appState.TQprofileId,
    });
    var URL = "/api/notes/profile/"+this.appState.TQprofileId;
    try
    {
      this.setTQAPI_led("green");
      let data = await this.http.get(URL, {headers}).toPromise()

      return data
    }
    catch
    {
      this.setTQAPI_led("red");
      this.apiError="Error on getNotesByProfile"
      throw(this.apiError)
    }
  }

  async getNoteSamplesByProfile(): Promise<any>
  {
    this.setTQAPI_led("yellow");
    const headers = new HttpHeaders({
      'Content-type': 'application/json' ,
      'Authorization' : 'Bearer ' + this.appState.Auth0Token,
      'tqapi_profile_id' : this.appState.TQprofileId,
    });
    var URL = "/api/note-samples/profile/"+this.appState.TQprofileId;
    try
    {
      let data = await this.http.get(URL, {headers}).toPromise()

      this.setTQAPI_led("green");
      return data
    }
    catch
    {
      this.setTQAPI_led("red");
      this.apiError="Error on getNoteSamplesByProfile"
      throw(this.apiError)
    }
  }

  async updateNoteOrder(note: any)
  {
    this.setTQAPI_led("yellow");
    const headers = new HttpHeaders({
      'Content-type': 'application/json' ,
      'Authorization' : 'Bearer ' + this.appState.Auth0Token,
      'tqapi_profile_id' : this.appState.TQprofileId,
    });
    var URL = "/api/notes/";
    try
    {
      let res = await this.http.patch(URL, note, {headers}).toPromise()

      this.setTQAPI_led("green");
      return res
    }
    catch
    {
      this.setTQAPI_led("red");
      this.apiError="Error on updateNoteOrder"
      throw(this.apiError)
    }
  }

  async updateNoteProject(note: any)
  {
    this.setTQAPI_led("yellow");
    const headers = new HttpHeaders({
      'Content-type': 'application/json' ,
      'Authorization' : 'Bearer ' + this.appState.Auth0Token,
      'tqapi_profile_id' : this.appState.TQprofileId,
    });
    var URL = "/api/notes/";
    try
    {
      let res = await this.http.patch(URL, note, {headers}).toPromise()

      this.setTQAPI_led("green");
      return res
    }
    catch
    {
      this.setTQAPI_led("red");
      this.apiError="Error on updateNoteProject"
      throw(this.apiError)
    }
  }
  

  /*********************************
   PROFILES
  *********************************/

  async getProfile():Promise<Profile>
  {
    this.setTQAPI_led("yellow");
    const headers = new HttpHeaders({
      'Content-type': 'application/json' ,
      'Authorization' : 'Bearer ' + this.appState.Auth0Token,
      'tqapi_profile_id' : this.appState.TQprofileId,
    });
    var URL = "/api/profiles/"+this.appState.TQprofileId;
    try
    {
      var res = await <Promise<Profile>>this.http.get(URL, {headers}).toPromise()

      this.setTQAPI_led("green");
      return res
    }
    catch
    {
      this.setTQAPI_led("red");
      this.apiError="Error on getProfile"
      throw(this.apiError)
    }
  }

  async postProfile(profile: any)
  {
    this.setTQAPI_led("yellow");

    const TQProfile = `{
      "authSubject" : "${profile.sub}",
      "email" : "${profile.email}"
    }`

    const headers = new HttpHeaders({
      'Content-type': 'application/json' ,
      'Authorization' : 'Bearer ' + this.appState.Auth0Token,
    });
    var URL = "/api/profiles/";
    try
    {
      var res = await this.http.post(URL, TQProfile, {headers}).toPromise()

      this.setTQAPI_led("green");
      return res
    }
    catch
    {
      this.setTQAPI_led("red");
      this.apiError="Error on postProfile"
      throw(this.apiError)
    }
  }

  async deleteProfile()
  {
    this.setTQAPI_led("yellow");
    const headers = new HttpHeaders({
      'Content-type': 'application/json' ,
      'Authorization' : 'Bearer ' + this.appState.Auth0Token,
      'tqapi_profile_id' : this.appState.TQprofileId,
    });
    var URL = "/api/profiles/"+this.appState.TQprofileId;
    try
    {
      await this.http.delete(URL, {headers}).toPromise()

      this.setTQAPI_led("green");
      return
    }
    catch
    {
      this.setTQAPI_led("red");
      this.apiError="Error on deleteProfile"
      throw(this.apiError)
    }
  }

  async updateProfile(profile: any)
  {
    this.setTQAPI_led("yellow");

    const headers = new HttpHeaders({
      'Content-type': 'application/json' ,
      'Authorization' : 'Bearer ' + this.appState.Auth0Token,
      'tqapi_profile_id' : this.appState.TQprofileId,
    });
    var URL = "/api/profiles/"+this.appState.TQprofileId;
    try
    {
      await this.http.patch(URL, profile, {headers}).toPromise()

      this.setTQAPI_led("green");
      return
    }
    catch
    {
      this.setTQAPI_led("red");
      this.apiError="Error on updating profile"
      throw(this.apiError)
    }
  }

  async sendVerificationEmail()
  {
    this.setTQAPI_led("yellow");
    const headers = new HttpHeaders({
      'Content-type': 'application/json' ,
      'Authorization' : 'Bearer ' + this.appState.Auth0Token,
      'tqapi_profile_id' : this.appState.TQprofileId,
    });
    var URL = "/api/profiles/"+this.appState.TQprofileId+"/email/verify";
    try
    {
      await this.http.post(URL, null, {headers}).toPromise()

      this.setTQAPI_led("green");
      return
    }
    catch
    {
      this.setTQAPI_led("red");
      this.apiError="Error on sendVerificationEmail"
      throw(this.apiError)
    }
  }

  async getProfileTimeZones()
  {
    this.setTQAPI_led("yellow");
    const headers = new HttpHeaders({
      'Content-type': 'application/json' ,
      'Authorization' : 'Bearer ' + this.appState.Auth0Token,
      'tqapi_profile_id' : this.appState.TQprofileId,
    });
    var URL = "/api/profiles/"+this.appState.TQprofileId+"/timezones";
    try
    {
      let res = await this.http.get(URL, {headers}).toPromise()

      this.setTQAPI_led("green");
      return res
    }
    catch
    {
      this.setTQAPI_led("red");
      this.apiError="Error on getProfileTimeZones"
      throw(this.apiError)
    }
  }

  async getProfileProductPromotions()
  {
    this.setTQAPI_led("yellow");
    const headers = new HttpHeaders({
      'Content-type': 'application/json' ,
      'Authorization' : 'Bearer ' + this.appState.Auth0Token,
      'tqapi_profile_id' : this.appState.TQprofileId,
    });
    var URL = "/api/profiles/"+this.appState.TQprofileId+"/products/promotions";
    try
    {
      let res = await this.http.get<String>(URL, {headers}).toPromise()

      this.setTQAPI_led("green");
      return res
    }
    catch
    {
      this.setTQAPI_led("red");
      this.apiError="Error on getProfileProductPromotions"
      throw(this.apiError)
    }
  }

  async getProfileGoogleCalendars(authCode: any)
  {
    this.setTQAPI_led("yellow");
    const headers = new HttpHeaders({
      'Content-type': 'application/json' ,
      'Authorization' : 'Bearer ' + this.appState.Auth0Token,
      'tqapi_profile_id' : this.appState.TQprofileId,
      'X-TQ-google_auth_code' : authCode,
    });
    var URL = "/api/profiles/"+this.appState.TQprofileId+"/calendars/google"; 
    try
    {
      let res = await this.http.get(URL, {headers}).toPromise()

      this.setTQAPI_led("green");
      return res
    }
    catch
    {
      this.setTQAPI_led("red");
      this.apiError="Error on getGoogleCalendars"
      throw(this.apiError)
    }
  }


  /*********************************
   PROJECTS
  *********************************/

  async getProject(id: number)
  {
    this.setTQAPI_led("yellow");
    const headers = new HttpHeaders({
      'Content-type': 'application/json' ,
      'Authorization' : 'Bearer ' + this.appState.Auth0Token,
      'tqapi_profile_id' : this.appState.TQprofileId,
    });
    var URL = "/api/projects/"+id;
    try
    {
      var res = await this.http.get(URL, {headers}).toPromise()

      this.setTQAPI_led("green");
      return res
    }
    catch
    {
      this.setTQAPI_led("red");
      this.apiError="Error on getProject"
      throw(this.apiError)
    }
  }

  async postProject(project: any)
  {
    this.setTQAPI_led("yellow");
    const headers = new HttpHeaders({
      'Content-type': 'application/json' ,
      'Authorization' : 'Bearer ' + this.appState.Auth0Token,
      'tqapi_profile_id' : this.appState.TQprofileId,
    });
    const URL = "/api/projects";
    try
    {
      var res = await this.http.post(URL, project, {headers}).toPromise()

      this.setTQAPI_led("green");
      return res
    }
    catch
    {
      this.setTQAPI_led("green");
      this.apiError="Error on postProject"
      throw(this.apiError)
    }
  }

  async deleteProject(id: number)
  {
    this.setTQAPI_led("yellow");
    const headers = new HttpHeaders({
      'Content-type': 'application/json' ,
      'Authorization' : 'Bearer ' + this.appState.Auth0Token,
      'tqapi_profile_id' : this.appState.TQprofileId,
    });
    var URL = "/api/projects/"+id;
    try
    {
      let res = await this.http.delete(URL, {headers}).toPromise()

      this.setTQAPI_led("green");
      return res
    }
    catch
    {
      this.setTQAPI_led("red");
      this.apiError="Error on deleteProject"
      throw(this.apiError)
    }
  }

  async updateProject(project: any)
  {
    this.setTQAPI_led("yellow");
    const headers = new HttpHeaders({
      'Content-type': 'application/json' ,
      'Authorization' : 'Bearer ' + this.appState.Auth0Token,
      'tqapi_profile_id' : this.appState.TQprofileId,
    });
    var URL = "/api/projects";
    try
    {
      let res = await this.http.patch(URL, project, {headers}).toPromise()

      this.setTQAPI_led("green");
      return res
    }
    catch
    {
      this.setTQAPI_led("red");
      this.apiError="Error on updating project"
      throw(this.apiError)
    }
  }


  async updateProjectAuthCodeByTaskId(project: any)
  {
    this.setTQAPI_led("yellow");
    const headers = new HttpHeaders({
      'Content-type': 'application/json' ,
      'Authorization' : 'Bearer ' + this.appState.Auth0Token,
      'tqapi_profile_id' : this.appState.TQprofileId,
    });
    var URL = "/api/projects/task/"+project.task_id+"/auth-code";
    try
    {
      let res = await this.http.patch(URL, project, {headers}).toPromise()

      this.setTQAPI_led("green");
      return res
    }
    catch
    {
      this.setTQAPI_led("red");
      this.apiError="Error on updating project auth code by task id"
      throw(this.apiError)
    }
  }


  async getProjectsByProfile(): Promise<any>
  {
    this.setTQAPI_led("yellow");
    const headers = new HttpHeaders({
      'Content-type': 'application/json' ,
      'Authorization' : 'Bearer ' + this.appState.Auth0Token,
      'tqapi_profile_id' : this.appState.TQprofileId,
    });
    var URL = "/api/projects/profile/"+this.appState.TQprofileId;
    try
    {
      let data = await this.http.get(URL, {headers}).toPromise()

      this.setTQAPI_led("green");
      return data
    }
    catch
    {
      this.setTQAPI_led("red");
      this.apiError="Error on getProjectsByProfile"
      throw(this.apiError)
    }
  }

  async getProjectSamplesByProfile(): Promise<any>
  {
    this.setTQAPI_led("yellow");
    const headers = new HttpHeaders({
      'Content-type': 'application/json' ,
      'Authorization' : 'Bearer ' + this.appState.Auth0Token,
      'tqapi_profile_id' : this.appState.TQprofileId,
    });
    var URL = "/api/project-samples/profile/"+this.appState.TQprofileId;
    try
    {
      let data = await this.http.get(URL, {headers}).toPromise()

      this.setTQAPI_led("green");
      return data
    }
    catch
    {
      this.setTQAPI_led("red");
      this.apiError="Error on getProjectSamplesByProfile"
      throw(this.apiError)
    }
  }

  async updateProjectOrder(project: any)
  {
    this.setTQAPI_led("yellow");
    const headers = new HttpHeaders({
      'Content-type': 'application/json' ,
      'Authorization' : 'Bearer ' + this.appState.Auth0Token,
      'tqapi_profile_id' : this.appState.TQprofileId,
    });
    var URL = "/api/projects/reorder";
    try
    {
      await this.http.post(URL, project, {headers}).toPromise()

      this.setTQAPI_led("green");
      return
    }
    catch
    {
      this.setTQAPI_led("red");
      this.apiError="Error on updateProjectOrder"
      throw(this.apiError)
    }
  }

  async importProjectFromGoogleCalendar(calendar)
  {
    this.setTQAPI_led("yellow");
    const headers = new HttpHeaders({

      'Content-type': 'application/json' ,
      'Authorization' : 'Bearer ' + this.appState.Auth0Token,
      'tqapi_profile_id' : this.appState.TQprofileId,
    });
    var URL = "/api/projects/calendars/google"; 
    try
    {
      let res = await this.http.post(URL, calendar, {headers}).toPromise()

      this.setTQAPI_led("green");
      return res
    }
    catch
    {
      this.setTQAPI_led("red");
      this.apiError="Error on importGoogleCalendar"
      throw(this.apiError)
    }
  }


  /*********************************
   ROLES
  *********************************/

  async getRole(id: number)
  {
    this.setTQAPI_led("yellow");
    const headers = new HttpHeaders({
      'Content-type': 'application/json' ,
      'Authorization' : 'Bearer ' + this.appState.Auth0Token,
      'tqapi_profile_id' : this.appState.TQprofileId,
    });
    var URL = "/api/role/"+id;
    try
    {
      let res = await <Promise<NoteSample[]>>this.http.get(URL, {headers}).toPromise()

      this.setTQAPI_led("green");
      return res
    }
    catch
    {
      this.setTQAPI_led("red");
      this.apiError="Error on getRole"
      throw(this.apiError)
    }
  }

  async getRolesByProfile(): Promise<Array<any>>
  {
    this.setTQAPI_led("yellow");
    const headers = new HttpHeaders({
      'Content-type': 'application/json' ,
      'Authorization' : 'Bearer ' + this.appState.Auth0Token,
      'tqapi_profile_id' : this.appState.TQprofileId,
    });
    var URL = "/api/roles/profile/"+this.appState.TQprofileId;
    try
    {
      let data = await <Promise<any[]>>this.http.get(URL, {headers}).toPromise()

      this.setTQAPI_led("green");
      return data
    }
    catch
    {
      this.setTQAPI_led("red");
      this.apiError="Error on getRolesByProfile"
      throw(this.apiError)
    }
  }
 

  /*********************************
   SUSCRIPTIONS
  *********************************/

  async postSubscription(subscription: any)
  {
    this.setTQAPI_led("yellow");
    const headers = new HttpHeaders({
      'Content-type': 'application/json' ,
      'Authorization' : 'Bearer ' + this.appState.Auth0Token,
      'tqapi_profile_id' : this.appState.TQprofileId,
    });
    var URL = "/api/subscriptions";
    try
    {
      let res = await this.http.post(URL, subscription, {headers}).toPromise()

      this.setTQAPI_led("green");
      return res
    }
    catch
    {
      this.setTQAPI_led("red");
      this.apiError="Error on postSubscription"
      throw(this.apiError)
    }
  }

  async cancelSubscription(subscription: any)
  {
    this.setTQAPI_led("yellow");
    const headers = new HttpHeaders({
      'Content-type': 'application/json' ,
      'Authorization' : 'Bearer ' + this.appState.Auth0Token,
      'tqapi_profile_id' : this.appState.TQprofileId,
    });
    var URL = "/api/subscriptions";
    try
    {
      let res = await this.http.patch(URL, subscription, {headers}).toPromise()

      this.setTQAPI_led("green");
      return res
    }
    catch
    {
      this.setTQAPI_led("red");
      this.apiError="Error on updateSubscription"
      throw(this.apiError)
    }
  }

  async deleteSubscription(id: number)
  {
    this.setTQAPI_led("yellow");
    const headers = new HttpHeaders({
      'Content-type': 'application/json' ,
      'Authorization' : 'Bearer ' + this.appState.Auth0Token,
      'tqapi_profile_id' : this.appState.TQprofileId,
    });
    var URL = "/api/subscriptions/"+id;
    try
    {
      let res = await this.http.delete(URL, {headers}).toPromise()

      this.setTQAPI_led("green");
      return res
    }
    catch
    {
      this.setTQAPI_led("red");
      this.apiError="Error on deleteSubscription"
      throw(this.apiError)
    }
  }

  async getSubscriptionsByProfile():Promise<Array<any>>
  {
    this.setTQAPI_led("yellow");
    const headers = new HttpHeaders({
      'Content-type': 'application/json' ,
      'Authorization' : 'Bearer ' + this.appState.Auth0Token,
      'tqapi_profile_id' : this.appState.TQprofileId,
    });
    var URL = "/api/subscriptions/profile/"+this.appState.TQprofileId;
    try
    {
      let data = await <Promise<Array<any>>>this.http.get(URL, {headers}).toPromise()

      this.setTQAPI_led("green");
      return data
    }
    catch
    {
      this.setTQAPI_led("red");
      this.apiError="Error on getSubscriptionsByProfile"
      throw(this.apiError)
    }
  }


  /*********************************
   TASKS
  *********************************/

  /**
   * Returns a task of a given profile with all fields included
   * Dates and times are converted from UTC ISO to Profile TZ and format 
   */
  async getTask(id: number):Promise<Task>
  {
    this.setTQAPI_led("yellow");
    const headers = new HttpHeaders({
      'Content-type': 'application/json' ,
      'Authorization' : 'Bearer ' + this.appState.Auth0Token,
      'tqapi_profile_id' : this.appState.TQprofileId,
    });
    var URL = "/api/tasks/"+id;
    var res: any;
    try
    {
      res = await this.http.get(URL, {headers}).toPromise()

      // Transform UTC ISO DB dates 
      // Dates are transformed to PrfTZ PrfFmt
      // Times are transformed to PrfTZ ISO
      this.getTransformedTaskDates(res, "startWorkDate", "startWorkTime", "startWorkTZ", "startWorkTZDate", "startWorkTZTime")
      this.getTransformedTaskDates(res, "endWorkDate", "endWorkTime", "endWorkTZ", "endWorkTZDate", "endWorkTZTime")
      this.getTransformedTaskDates(res, "dueDate", "dueTime", "dueTZ", "dueTZDate", "dueTZTime")
      this.getTransformedTaskDates(res, "targetDate", "targetTime", "targetTZ", "targetTZDate", "targetTZTime")
      this.getTransformedTaskDates(res, null, "exeTime", "exeTZ", null, "exeTZTime")

      this.setTQAPI_led("green");
      return res
    }
    catch
    {
      this.setTQAPI_led("red");
      this.apiError="Error on getTask:" + res
      throw(this.apiError)
    }
  }

  getTransformedTaskDates(task: any, dateKey: string, timeKey: string, tzKey: string, tzDateKey: string, tzTimeKey: string)  
  {
    let date = task[dateKey] 
    let time = task[timeKey]
    let tz = task[tzKey]
    let tzDate = task[tzDateKey]
    let tzTime = task[tzTimeKey]
    if (tz == null)  
    {
      // Convert from UTC ISO to PrfTZ PrfFmt/ISO
      task[dateKey] = this.tqDT.dateToTZ(date,time)
      task[timeKey] = this.tqDT.timeToTZ(date,time)
      // Blank TZDate and TZTime
      task[tzDateKey] = ""
      task[tzTimeKey] = ""
    }
    else if (tz == "N") 
    {
      // Keep DB date and time
      task[dateKey] = this.tqDT.dateToTZ(date, null) // TODO Review
      task[timeKey] = time 
      // Blank TZDate and TZTime
      task[tzDateKey] = ""
      task[tzTimeKey] = ""
    }
    else 
    {
      // Convert from ISO TZ, date and time to PrfTZ PrfFmt
      task[dateKey] = this.tqDT.dateToTZ(tzDate, tzTime, tz)
      task[timeKey] = this.tqDT.timeToTZ(tzDate, tzTime, tz)
      // Keep DB date and time
      task[tzDateKey] = this.tqDT.formatDateFromISO(tzDate)
      task[tzTimeKey] = tzTime
    }
  }

  /**
   * POST a task to TQAPI
   * @param task, all dates must be in ISO format
   * @returns API response
   */
  async postTask(task: any)
  {
    this.setTQAPI_led("yellow");
    const headers = new HttpHeaders({
      'Content-type': 'application/json' ,
      'Authorization' : 'Bearer ' + this.appState.Auth0Token,
      'tqapi_profile_id' : this.appState.TQprofileId,
    });
    var URL = "/api/tasks/";
    try
    {
      // Transform UI dates to DB dates
      this.putTransformedTaskDates(task, "startWorkDate", "startWorkTime", "startWorkTZ", "startWorkTZDate", "startWorkTZTime")
      this.putTransformedTaskDates(task, "endWorkDate", "endWorkTime", "endWorkTZ", "endWorkTZDate", "endWorkTZTime")
      this.putTransformedTaskDates(task, "dueDate", "dueTime", "dueTZ", "dueTZDate", "dueTZTime")
      this.putTransformedTaskDates(task, "targetDate", "targetTime", "targetTZ", "targetTZDate", "targetTZTime")
      this.putTransformedTaskDates(task, null, "exeTime", "exeTZ", null, "exeTZTime")

      let res = await this.http.post(URL, task, {headers}).toPromise()

      this.setTQAPI_led("green");
      return res
    }
    catch
    {
      this.setTQAPI_led("red");
      this.apiError="Error on postTask"
      throw(this.apiError)
    }
  }

  putTransformedTaskDates(task: any, dateKey: string, timeKey: string, tzKey: string, tzDateKey: string, tzTimeKey: string)  
  {
    let date = task[dateKey]
    let time = task[timeKey]
    let tz = task[tzKey]
    if (tz == null || tz == "")  
    {
      // Convert from profile TZ to UTC
      task[dateKey] = this.tqDT.dateToUTC(date,time)
      task[timeKey] = this.tqDT.timeToUTC(date,time)
      // Blank TZDate and TZTime 
      task[tzKey] = ""
      task[tzDateKey] = ""
      task[tzTimeKey] = ""
    }
    else if (tz == "N") 
    {
      // Keep literal date and time
      task[dateKey] = date
      task[timeKey] = time
      // Blank TZDate and TZTime 
      task[tzKey] = "N"
      task[tzDateKey] = ""
      task[tzTimeKey] = ""
    }
    else 
    {
      // Convert from original TZ to UTC
      task[dateKey] = this.tqDT.dateToUTC(date,time)
      task[timeKey] = this.tqDT.timeToUTC(date,time)
      // Same TZDate and TZTime as received
    }
  }

  /**
   * PATCH a task to TQAPI
   * @param task, all dates must be in ISO format but profile TZ
   * @returns API response
   */
  async updateTask(task: any)
  {
    this.setTQAPI_led("yellow");
    const headers = new HttpHeaders({
      'Content-type': 'application/json' ,
      'Authorization' : 'Bearer ' + this.appState.Auth0Token,
      'tqapi_profile_id' : this.appState.TQprofileId,
    });
    var URL = "/api/tasks/";
    try
    {
      let date 
      let time 
      if (task["startWorkDate"])
      {
        if (task["startWorkTZ"] == null)
        {
          date = task["startWorkDate"] || ""
          time = task["startWorkTime"] || ""
          task["startWorkDate"] = this.tqDT.dateToUTC(date, time)
          if (task["startWorkTime"])
          {
            task["startWorkTime"] = this.tqDT.timeToUTC(date, time)
          }
        }
      }
      if (task["endWorkDate"])
      {
        if (task["endWorkTZ"] == null)
        {
          date = task["endWorkDate"] || ""
          time = task["endWorkTime"] || ""
          task["endWorkDate"] = this.tqDT.dateToUTC(date,time)
          if (task["endWorkTime"])
          {
            task["endWorkTime"] = this.tqDT.timeToUTC(date,time)
          }
        }
      }
      if (task["dueDate"])
      {
        if (task["dueTZ"] == null)
        {
          date = task["dueDate"] || ""
          time = task["dueTime"] || ""
          task["dueDate"] = this.tqDT.dateToUTC(date,time)
          if (task["dueTime"])
          {
            task["dueTime"] = this.tqDT.timeToUTC(date,time) 
          }
        }
      }
      if (task["targetDate"])
      {
        if (task["targetTZ"] == null)
        {
          date = task["targetDate"] || ""
          time = task["targetTime"] || ""
          task["targetDate"] = this.tqDT.dateToUTC(date,time)
          if (task["targetTime"])
          {
            task["targetTime"] = this.tqDT.timeToUTC(date,time) 
          }
        }
      }
      if (task["exeTime"])
        {
          if (task["exeTZ"] == null)
          {
            time = task["exeTime"] || ""
            task["exeTime"] = this.tqDT.timeToUTC(null,time) 
          }
        }
  
      let res = await this.http.patch(URL, task, {headers}).toPromise()

      this.setTQAPI_led("green");
      return res
    }
    catch (error)
    {
      this.setTQAPI_led("red");
      this.apiError="Error on updateTask"
      throw(this.apiError)
    }
  }

  async deleteTask(id: number)
  {
    this.setTQAPI_led("yellow");
    const headers = new HttpHeaders({
      'Content-type': 'application/json' ,
      'Authorization' : 'Bearer ' + this.appState.Auth0Token,
      'tqapi_profile_id' : this.appState.TQprofileId,
    });
    var URL = "/api/tasks/"+id;
    try
    {
      let res = await this.http.delete(URL, {headers}).toPromise()

      this.setTQAPI_led("green");
      return res
    }
    catch
    {
      this.setTQAPI_led("red");
      this.apiError="Error on deleteTask"
      throw(this.apiError)
    }
  }

  async duplicateTask(id: number)
  {
    this.setTQAPI_led("yellow");
    const headers = new HttpHeaders({
      'Content-type': 'application/json' ,
      'Authorization' : 'Bearer ' + this.appState.Auth0Token,
      'tqapi_profile_id' : this.appState.TQprofileId,
    });
    var URL = "/api/tasks/"+id+"/duplicate/";
    try
    {
      let res = await this.http.get(URL, {headers}).toPromise()

      this.setTQAPI_led("green");
      return res
    }
    catch
    {
      this.setTQAPI_led("red");
      this.apiError="Error on duplicateTask"
      throw(this.apiError)
    }
  }

  async getTasksByProfile()
  {
    const headers = new HttpHeaders({
      'Content-type': 'application/json' ,
      'Authorization' : 'Bearer ' + this.appState.Auth0Token,
      'tqapi_profile_id' : this.appState.TQprofileId,
    });
    var URL = "/api/tasks/profile/"+this.appState.TQprofileId;
    try
    {
      let data = await this.http.get(URL, {headers}).toPromise()

      return data
    }
    catch
    {
      this.apiError="Error on getTasksByProfile"
      throw(this.apiError)
    }
  }

  /*** 
   * Returns the list of tasks of a given profile 
   * with a minimal set of fields and dates 
   * converted from ISO UTC to Profile TZ and UI format 
  ***/
  async getTaskSamplesByProfile(): Promise<TaskSample[] | null>
  {
    this.setTQAPI_led("yellow");
    const headers = new HttpHeaders({
      'Content-type': 'application/json' ,
      'Authorization' : 'Bearer ' + this.appState.Auth0Token,
      'tqapi_profile_id' : this.appState.TQprofileId,
      'X-TQDB-timestamp' : this.getTQDBtimestamp("tasks"),
    });
    const URL = "/api/task-samples/profile/"+this.appState.TQprofileId;
    try
    {
      const res = await this.http.get(URL, {headers, observe:'response'}).toPromise()
      if (res.headers.has('X-TQDB-timestamp'))
      {
        const newTag = res.headers.get('X-TQDB-timestamp')
        const oldTag = this.getTQDBtimestamp("tasks")

        this.setTQDBtimestamp("tasks", newTag)

        if (newTag <= oldTag) 
        {
          return null
        }
      }

      const data = <TaskSample[]>res.body;
      for (let item of data) 
      {
        this.getTransformedTaskDates(item, "startWorkDate", "startWorkTime", "startWorkTZ", "startWorkTZDate", "startWorkTZTime")
        this.getTransformedTaskDates(item, "endWorkDate", "endWorkTime", "endWorkTZ", "endWorkTZDate", "endWorkTZTime")
        this.getTransformedTaskDates(item, "dueDate", "dueTime", "dueTZ", "dueTZDate", "dueTZTime")
        this.getTransformedTaskDates(item, "targetDate", "targetTime", "targetTZ", "targetTZDate", "targetTZTime")
        this.getTransformedTaskDates(item, null, "exeTime", "exeTZ", null, "exeTZTime")
      }

      this.setTQAPI_led("green");
      return data
    }
    catch(errror)
    {
      this.setTQAPI_led("red");
      this.apiError="Error on getTaskSamplesByProfile"
      throw(this.apiError)
    }
  }
  

  /*********************************
   TQCONTROLS
  *********************************/

  async getTQcontrolTimestamp(key: string)
  {
    this.setTQAPI_led("yellow");
    const headers = new HttpHeaders({
      'Content-type': 'application/json' ,
      'Authorization' : 'Bearer ' + this.appState.Auth0Token,
      'tqapi_profile_id' : this.appState.TQprofileId,
    });
    var URL = "/api/tqcontrol/timestamp/"+key;
    try
    {
      let res = await this.http.get(URL, {headers}).toPromise()
      this.setTQAPI_led("green");
      return res
    }
    catch
    {
      this.setTQAPI_led("red");
      this.apiError="Error on getTQcontrolTimestampd"
      throw(this.apiError)
    }
  }

  public getTQDBtimestamp(entity: string): string
  {
    return this.getApiTQDBtimestamp("TQDB_"+entity+"_timestamp")
  }

  public setTQDBtimestamp(entity: string, ts: string): void
  {
    this.setApiTQDBtimestamp("TQDB_"+entity+"_timestamp", ts)
  }

  public getApiTQDBtimestamp(entity: string): string
  {
    return this.apiTQDBtimestamps.get(entity)
  }
  
  public setApiTQDBtimestamp(entity: string, eTag: string): void 
  {
    this.apiTQDBtimestamps.set(entity, eTag);
  }

}

  
/*** -------------------------------------------------------
 * TQApiErrorInterceptor 
 */

@Injectable({
  providedIn: 'root'
})
export class TQApiErrorInterceptor implements HttpInterceptor
{
    constructor
    (
      private router: Router,
      private googleCalendarService: CalendarService,
      private samApp: SamuraiService,
    ) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>
    {
      return next.handle(request).pipe(
        catchError(err =>
        {
          this.samApp.debug("TQAPI HTTP Error Intercepted from URL: " + request.url)
          this.samApp.debug("Status: [" + err.status + "] Message: [ " + err.message + " ]");

          if ([503, 504].includes(err.status))
          {
            // Do not to active another error, for it loops
            return NEVER;
          }

          if ([401, 403].includes(err.status)) 
          {
            if ( err.status == 403 && err.error.code == "invalid_grant")
            {
              this.samApp.trace("Reconnecting Google Calendar...")
              // this.samApp.trace("request: " + JSON.stringify(request))

              // alert("You do not have access to the Google Calendar. \nPlease sign in and try again.")
              
              this.googleCalendarService.reconnectGoogleCalendar(request.body);

              return throwError(err);  
            }
  
            this.router.navigate(['/signin']);
            return NEVER   
          }

          if (err.status != 0 && err.status != 200)
          {
            return throwError(err);  
          }
        })
      )
    }
}

