import { environment } from 'src/environments/environment';

import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from "@angular/router";

import { Store } from '@ngrx/store';
import { AppState } from 'src/app/appState/app.state';
import * as APP_ACTIONS from 'src/app/appState/app.actions';
import { appState } from 'src/app/appState/app.selectors';
import { ProjectsState } from 'src/app/projects/store/projects.state';
import * as PROJECTS_ACTIONS from 'src/app/projects/store/projects.actions';
import { projectsState } from 'src/app/projects/store/projects.selectors';
import * as TASKS_ACTIONS from 'src/app/tasks/store/tasks.actions';

import { CalendarService } from 'src/app/services/google/calendar.service';
import { SamuraiService } from 'src/services/samurai/samurai.service';
import { TQApiService } from 'src/app/services/tqapi.service';
import { TQSessionService } from 'src/app/services/tqsession.service';

import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';


declare var google: any;

@Component({
  selector: 'app-project-importer',
  templateUrl: './project-importer.component.html',
  styleUrl: './project-importer.component.scss'
})
export class ProjectImporterComponent implements OnInit, OnDestroy
{
  faQuestionCircle = faQuestionCircle;

  appState: AppState;
  appStateSubs: any;

  projectsState: ProjectsState;
  projectsStateSubs: any;
  canAddProject: boolean = false;

//  googleCalendarApiClient = null;
  googleCalendarAccessRole = null;
  googleCalendarAuthCode = null;
  googleCalendarAccount = null;
  googleCalendarsList = null;
  googleCalendarSync = true;
  googleCalendarId = null;
  googleCalendarSummary = null;


  constructor
  (
    private router: Router,
    private store: Store,
    private googleCalendarService: CalendarService,
    private tqApi: TQApiService,
    private tqSession: TQSessionService,
  )
  {}

  async ngOnInit()
  {
    this.appStateSubs = this.store.select(appState)
      .subscribe( state => {
        this.appState = state 
        
        this.canAddProject = this.tqSession.canAddProject();
      })

    this.projectsStateSubs = this.store.select(projectsState)
      .subscribe( state => {
        this.projectsState = state 

        this.canAddProject = this.tqSession.canAddProject();

      })
    }
    ngOnDestroy()
    {
      this.appStateSubs.unsubscribe();
      this.projectsStateSubs.unsubscribe();
    }
  
    async getGoogleCalendars()
    {
      // Set the Google Calendar client with a callback
      const googleCalendar = await this.googleCalendarService.setGoogleCalendarClientCallback(
        async (response) => {
          this.googleCalendarAuthCode = response.code;

          let res = await this.tqApi.getProfileGoogleCalendars(this.googleCalendarAuthCode)
          this.googleCalendarsList = res
        })
       
      // Trigger the Google Calendar callback
      this.googleCalendarService.connectGoogleCalendar(googleCalendar);
    }

    async importGoogleCalendar()
    {
      // Getting Google Calendar Events...
        let calendar = {
          profile_id: this.appState.TQprofileId,
          parent_project_id: this.appState.projectFilterId,
          google_auth_code: this.googleCalendarAuthCode,
          google_calendar_id: this.googleCalendarId,
          google_calendar_sync: this.googleCalendarSync,
          google_access_role: this.googleCalendarsList.find(c => c.id == this.googleCalendarId).accessRole,
        }
        let res = await this.tqApi.importProjectFromGoogleCalendar(calendar);

        // Update sidebar with the new project
        this.store.dispatch(PROJECTS_ACTIONS.loadProjectsList())
        this.store.dispatch(PROJECTS_ACTIONS.selectProject({id: res['project_id']})); 
       
        this.store.dispatch(TASKS_ACTIONS.loadTasksList());

        this.router.navigate(['projectpad'])
    }

    cancelImport()
    {
      this.router.navigate(['projectpad'])
    }
}
