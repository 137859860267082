import { createAction, props } from '@ngrx/store';

import { Project, ProjectSample } from 'src/app/models/project';

// TQprojects
export const loadProjectsList = createAction(
  '[Projects] Load Projects',
);
export const loadProjectsListOK = createAction(
  '[Projects] Load Projects OK',
  props<{ projects: Array<ProjectSample> }>()
);
export const loadProjectsListKO = createAction(
  '[Projects] Load Projects KO',
  props<{ error: string }>()
);

export const selectProject = createAction(
  '[Project] Select Project',
  props<{ id: number }>()
);

