import { Role } from 'src/app/models/role';

import { DateTime } from "luxon";


/**
 * Auth0Profile is the profile returned by Auth0 after a successful login.
 * Example:
 * {
 * "nickname":"test1",
 * "name":"test1@taskquark.com",
 * "picture":"https://s.gravatar.com/avatar/c310cf522fba2d9e2766bae7ee0a2ecd?s=480&r=pg&d=https%3A%2F%2Fcdn.auth0.com%2Favatars%2Fte.png",
 * "updated_at":"2024-01-26T08:41:48.185Z",
 * "email":"test1@taskquark.com",
 * "email_verified":true,
 * "sub":"auth0|65b32a1944951676f899e32f"}
 */
export interface Auth0Profile
{
  email: string,
  email_verified: boolean,
  sub: string,
}

export interface TQSubscription
{
  id: number,
  plan: string,
  startDate: string,
  lastPayment: string,
  dateCanceled: string,
}

export const CONSTANTS = {
  ROLE: {
    ALL_code:        "<ALL>",
    ALL_description: "All roles"
  }
}

export interface AppState 
{
  // --- This is TQapplication state
  // Maintained by appComponent

  status: string; // 'init' | 'loading' | 'loaded' | 'error'

  onMobile: boolean;

  sidebarActive: boolean;
  sidebarCollapsed: boolean;

  lastGuardTime: DateTime;
  backURL: string;
  lastPane: string;
  activePane: string;
  
  // --- This is TQsession state
  // Maintained by TQSessionService

  Auth0Profile: Auth0Profile;
  Auth0Token: string;

  // Profile
  TQprofileId: number;
  TQprofileEmail: string;
  default_project_id: number;

  // Profile Preferences
  prefLoginStartPad;
  prefLogoutAfterInactiveFor;
  prefLocTimeZone;
  prefLocWeekStart;
  prefLocWeekendDays;
  prefLocDateFormat;
  prefLocTimeFormat;
  prefWorkpadNumCols;
  prefWorkpadCol1;
  prefWorkpadCol2; 
  prefWorkpadCol3;
  prefWorkpadCol4;
  prefWorkpadCol5;
  prefWorkpadCol1Mobile;
  prefWorkpadCol2Mobile; 
  prefWorkpadCol3Mobile;
  prefWorkpadCol4Mobile;
  prefWorkpadCol5Mobile;
  prefTaskRadarEnabled;
  prefTaskRadarFreq;
  prefTaskRadarRange;

  // Profile Configuration 
  cfgRoleSelectedId;
    
  // WEB app state
  TQroleSelectedId: number;
  TQroleSelected: Role;
  TQroles: Array<Role>;

  // TQproductPromotions: string,

  lastSubscription: TQSubscription;
  trialSubscription: TQSubscription;

  // TODO Refactor to TQprojectSelected
  projectFilterId: number;
  projectFilterCode: string;
  projectFilterColor: string;
  projectFilterList: Array<number>

  // More state in TQsessionService
}

export const initialAppState: AppState = 
{
  // This is TQapplication state

  status: 'init',

  onMobile: false,

  sidebarActive: false,
  sidebarCollapsed: false,
  
  lastGuardTime: DateTime.now(),

  backURL: '/workpad',
  lastPane: 'workpad',
  activePane: 'workpad',
  
  // This is TQsession state

  Auth0Token: null,
  Auth0Profile: {
    email: null,
    email_verified: false,
    sub: null,
  },

  TQprofileId: 0,
  TQprofileEmail: null,
  default_project_id: 0,
  //--- Profile Preferences ---//
  prefLoginStartPad: "workpad",
  prefLogoutAfterInactiveFor: "60",
  prefLocTimeZone: "UTC",
  prefLocWeekStart: "Sunday",
  prefLocWeekendDays: "Sa Su",
  prefLocDateFormat: "YYYY-MM-DD",
  prefLocTimeFormat: "24h",
  prefWorkpadNumCols: 5,
  prefWorkpadCol1: "planning",
  prefWorkpadCol2: "todo",
  prefWorkpadCol3: "doing",
  prefWorkpadCol4:"waiting",
  prefWorkpadCol5: "stopped",
  prefWorkpadCol1Mobile: "planning",
  prefWorkpadCol2Mobile: "todo",
  prefWorkpadCol3Mobile: "doing",
  prefWorkpadCol4Mobile: "waiting",
  prefWorkpadCol5Mobile: "stopped",
  prefTaskRadarEnabled: false,
  prefTaskRadarFreq: "monthly",
  prefTaskRadarRange: "60d",
  //--- Profile Configuration ---//
  cfgRoleSelectedId: null,
  
  TQroleSelectedId: null,
  TQroleSelected: {
    id: null,
    code: CONSTANTS.ROLE.ALL_code,
    description: CONSTANTS.ROLE.ALL_description,
  },
  TQroles: [],

//  TQproductPromotions: '',  // TODO Remove because it is stored in TQSessionService

  lastSubscription: {
    id: null,
    plan: null,
    startDate: null,
    lastPayment: null,
    dateCanceled: null,
  },
  trialSubscription: {
    id: null,
    plan: null,
    startDate: null,
    lastPayment: null,
    dateCanceled: null,
  },

  projectFilterId: 0,
  projectFilterCode: '',
  projectFilterColor: '',
  projectFilterList: [],  

}

