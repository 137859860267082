import { Project } from "./project";

export interface Task 
{
  id: number;
  profile_id: number;
  project_id: number;
  title: string;
  description: string;
  priority: string;
  status: string;
  relDate: string;
  startWorkDate: string;
  startWorkTime: string;
  startTZ: string;
  startTZDate: string;
  startTZTime: string;
  endWorkDate: string;
  endWorkTime: string;
  endTZ: string;
  endTZDate: string;
  endTZTime: string;
  dueDate: string;
  dueTime: string;
  dueTZ: string;
  dueTZDate: string;
  dueTZTime: string;
  targetDate: string;
  targetTime: string;
  targetTZ: string;
  targetTZDate: string;
  targetTZTime: string;
  exeTime: string;
  exeDuration: string;
  repWeekDay: string,
  // repMo: boolean,
  // repTu: boolean,
  // repWe: boolean,
  // repTh: boolean,
  // repFr: boolean,
  // repSa: boolean,
  // repSu: boolean,
  repDay: string,
  repJan: boolean,
  repFeb: boolean,
  repMar: boolean,
  repApr: boolean,
  repMay: boolean,
  repJun: boolean,
  repJul: boolean,
  repAug: boolean,
  repSep: boolean,
  repOct: boolean,
  repNov: boolean,
  repDec: boolean,
  origin: string,
}

export interface TaskSample 
{
  id: number;
  project_id: number;
  project_code: string;
  title: string;
  hasDescription: boolean;
  activityCount: number;
  priority: string;
  status: string;
  relDate: string;
  startWorkDate: string;
  startWorkTime: string;
  startTZ: string;
  startTZDate: string;
  startTZTime: string;
  endWorkDate: string;
  endWorkTime: string;
  endTZ: string;
  endTZDate: string;
  endTZTime: string;
  dueDate: string;
  dueTime: string;
  dueTZ: string;
  dueTZDate: string;
  dueTZTime: string;
  targetDate: string;
  targetTime: string;
  targetTZ: string;
  targetTZDate: string;
  targetTZTime: string;
  exeTime: string;
  exeDuration: string;
  repWeekDays: string,
  // repMo: boolean,
  // repTu: boolean,
  // repWe: boolean,
  // repTh: boolean,
  // repFr: boolean,
  // repSa: boolean,
  // repSu: boolean,
  repDay: string,
  repJan: boolean,
  repFeb: boolean,
  repMar: boolean,
  repApr: boolean,
  repMay: boolean,
  repJun: boolean,
  repJul: boolean,
  repAug: boolean,
  repSep: boolean,
  repOct: boolean,
  repNov: boolean,
  repDec: boolean,
  origin: string,
}

export function hasRepetitionOnNumberDay(t) 
{
  if (t.repDay != null) return true;

  return false;
}

export function taskRepeatsThisNumberDay(t, d)
{
  if (!hasRepetitionOnNumberDay(t)) return false;

  if (t.repDay == d) return true;

  return false;
}

// TODO use repWeekDay instead
// export function hasRepetitionOnWeekdays(t) 
// {
//   if (t.repMo || t.repTu || t.repWe || t.repTh || t.repFr || t.repSa || t.repSu) return true;

//   return false;
// }

export function hasRepetitionOnWeekdays(t) 
{
  if (t.repWeekDays != null && t.repWeekDays !="" ) return true;

  return false;
}

// TODO use repWeekDay instead
// export function taskRepeatsThisWeekday(t, d)
// {
//   if (!hasRepetitionOnWeekdays(t)) return false;

//   switch (d) {
//     case 1: return t.repMo;
//     case 2: return t.repTu;
//     case 3: return t.repWe;
//     case 4: return t.repTh;
//     case 5: return t.repFr;
//     case 6: return t.repSa;
//     case 7: return t.repSu;
//     default: return false;
//   }}

  export function taskRepeatsThisWeekday(t, d)
  {
    if (!hasRepetitionOnWeekdays(t)) return false;
  
    switch (d) {
      case 1: return t.repWeekDays.includes("MO");
      case 2: return t.repWeekDays.includes("TU");
      case 3: return t.repWeekDays.includes("WE");
      case 4: return t.repWeekDays.includes("TH");
      case 5: return t.repWeekDays.includes("FR");
      case 6: return t.repWeekDays.includes("SA");
      case 7: return t.repWeekDays.includes("SU");
      default: return false;
    }
  }
  
  export function hasRepetitionOnMonths(t) 
{
  if (t.repJan || t.repFeb || t.repMar || t.repApr || t.repMay || t.repJun || t.repJul || t.repAug || t.repSep || t.repOct || t.repNov || t.repDec) return true;
  
  return false;
}

export function taskRepeatsThisMonth(t, m)
{
  if (!hasRepetitionOnMonths(t)) return false;

  if (!t.repJan && !t.repFeb && !t.repMar && !t.repApr && !t.repMay && !t.repJun && !t.repJul && !t.repAug && !t.repSep && !t.repOct && !t.repNov && !t.repDec) return true;

  if (m ==  1 && t.repJan == false ) return false;
  if (m ==  2 && t.repFeb == false ) return false;
  if (m ==  3 && t.repMar == false ) return false;
  if (m ==  4 && t.repApr == false ) return false;
  if (m ==  5 && t.repMay == false ) return false;
  if (m ==  6 && t.repJun == false ) return false;
  if (m ==  7 && t.repJul == false ) return false;
  if (m ==  8 && t.repAug == false ) return false;
  if (m ==  9 && t.repSep == false ) return false;
  if (m == 10 && t.repOct == false ) return false;
  if (m == 11 && t.repNov == false ) return false;
  if (m == 12 && t.repDec == false ) return false;

  return true;
}
