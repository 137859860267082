import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { from, of } from 'rxjs';
import { map, mergeMap, switchMap, catchError } from 'rxjs/operators';

import * as NOTES_ACTIONS from './notes.actions';

import { TQApiService } from 'src/app/services/tqapi.service';

@Injectable()
export class NoteEffects {

  constructor(
    private actions$: Actions,
    private tqAPI: TQApiService
  ) { }

  loadNotesList$ = createEffect(() =>
    this.actions$
      .pipe(
        ofType(NOTES_ACTIONS.loadNotesList),
          switchMap(() => 
            from(this.tqAPI.getNoteSamplesByProfile())
              .pipe(
                map(notes => 
                  notes ? NOTES_ACTIONS.loadNotesListOK({ notes }) : null ), //NOTES_ACTIONS.loadNotesListSAME()),
                catchError(error => 
                  of(NOTES_ACTIONS.loadNotesListKO({ error })))
              ))
          ))

}