import { Component, HostListener, OnInit, OnDestroy } from '@angular/core';
import { Router } from "@angular/router";

import { CdkDragDrop, CdkDragStart } from '@angular/cdk/drag-drop';
import { TreeNode } from 'primeng/api';

import { Store } from "@ngrx/store";
import { AppState } from 'src/app/appState/app.state';
import { appState } from 'src/app/appState/app.selectors';
import * as APP_ACTIONS from 'src/app/appState/app.actions';
import { ProjectsState } from 'src/app/projects/store/projects.state';
import { projectsState } from 'src/app/projects/store/projects.selectors';
import * as PROJECTS_ACTIONS from 'src/app/projects/store/projects.actions';
import { NotesState } from 'src/app/notes/store/notes.state';
import { notesState } from 'src/app/notes/store/notes.selectors';
import * as NOTES_ACTIONS from 'src/app/notes/store/notes.actions';

import { SamuraiService } from 'src/services/samurai/samurai.service';
import { TQApiService } from 'src/app/services/tqapi.service';
import { TQSessionService } from 'src/app/services/tqsession.service';

import { faFile, faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import { faArrowUp, faFilter, faPlus, faRedo, faSpinner, faThumbtack, } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-notepad',
  templateUrl: './notepad.component.html',
  styleUrls: ['./notepad.component.scss'],
})
export class NotepadComponent implements OnInit, OnDestroy 
{
  faArrowUp = faArrowUp;
  faFile = faFile;
  faFilter = faFilter;
  faPlus = faPlus;
  faQuestionCircle = faQuestionCircle;
  faRedo = faRedo;
  faSpinner = faSpinner;
  faThumbtack = faThumbtack;

  componentState = 'init';

  appState: AppState;
  appStateSubs: any;

  projectsState: ProjectsState;
  projectsStateSubs: any;

  notesState: NotesState;
  notesStateSubs: any;

  canAddNote: boolean = true;

  noteId: number = 0; // Local copy of notesState.selectedNoteId
    
  notepadTree: TreeNode[] = [];

  notepadFilter: boolean = false;
  notepadFilterRegEx: string  = null ;

  constructor
  (
    private router: Router,
    private store: Store,
    private tqApi: TQApiService,
    private tqSession: TQSessionService,
    private samApp: SamuraiService,
  )
  {}

  async ngOnInit()
  {
    this.store.dispatch(APP_ACTIONS.setBackURL({ url:this.router.url }))    
    this.store.dispatch(APP_ACTIONS.enterTQpane( {pane: 'notepad' }))

    this.appStateSubs = this.store.select(appState)
      .subscribe( state => {
        this.appState = state 
        if (state.status != 'loaded') return;
        if (this.appState.activePane != 'notepad') return;

        this.canAddNote = this.tqSession.canAddNote();

        if (this.componentState == 'loaded')
        {

          this.buildNotepad();
        }
      })

    this.projectsStateSubs = this.store.select(projectsState)
      .subscribe( state => {
        this.projectsState = state 
        if (state.status != 'loaded') return;
        if (this.appState.activePane != 'notepad') return;
        
        this.canAddNote = this.tqSession.canAddNote();

        if (this.componentState == 'loaded')
        {       
          this.buildNotepad();
        }
      })

    this.notesStateSubs = this.store.select(notesState)
      .subscribe( state => {
        this.notesState = state 
        if (state.status != 'loaded') return;
        if (this.appState.activePane != 'notepad') return;

        this.canAddNote = this.tqSession.canAddNote();
        
        if (this.componentState = 'loaded')
        {
          this.noteId=state.selectedNoteId;
          this.buildNotepad();
        }
      })
  
    this.notepadFilter=this.samApp.retrieveStore("TQnotepad.filter", "session")
    this.notepadFilterRegEx=this.samApp.retrieveStore("TQnotepad.filterRegEx", "session")

//    if (this.tqSession.getStatus() == 'loaded')  
    {
      this.store.dispatch(NOTES_ACTIONS.loadNotesList());
    }

    this.componentState = 'loaded';
  }

  ngOnDestroy()
  {
    this.appStateSubs.unsubscribe();
    this.notesStateSubs.unsubscribe();
    this.notesStateSubs.unsubscribe();
  }

  @HostListener('document:keydown.control.f', ['$event'])
  CtrlF(event: any)
  {
    event.preventDefault();
    this.clearNotepadFilter();
    this.notepadFilter = true;

    this.samApp.saveStore("TQnotepad.filter", this.notepadFilter, "session")
    this.samApp.saveStore("TQnotepad.filterRegEx", this.notepadFilterRegEx, "session")
  }
  @HostListener('document:keydown', ['$event'])
  KeyDownOnFilter(event: any)
  {
    if (this.notepadFilter == false) return;

    // Force Notepad update after processing the key
    setTimeout( () => { this.buildNotepad(); },0)

    this.samApp.saveStore("TQnotepad.filter", this.notepadFilter, "session")
    this.samApp.saveStore("TQnotepad.filterRegEx", this.notepadFilterRegEx, "session")
  }
  @HostListener('document:keydown.escape', ['$event'])
  Escape(event: any)
  {
    event.preventDefault();
    if (this.notepadFilter == true)
    {
      this.clearNotepadFilter();
      this.notepadFilter = false;

      this.samApp.saveStore("TQnotepad.filter", this.notepadFilter, "session")
      this.samApp.saveStore("TQnotepad.filterRegEx", this.notepadFilterRegEx, "session")
    }
    else if (this.noteId == 0)
    {
      this.clearNotepadFilter()
    }
    else     
    {
      this.clearToTop()
    }
  }
  @HostListener('document:keydown.Alt.escape', ['$event'])
  AltEscape(event: any)
  {
    event.preventDefault();

    this.clearProjectFilter()
  }

  dragStarted(event: CdkDragStart, node: TreeNode)
  {
    function disableProjects(tree, parent)
    {
      tree.forEach(p => {
        //p.data.droppable = !p.data.is_project;
        p.data.droppable = true;
        if (p.data.is_project || p.data.parent_project_id != parent) 
        {
          p.data.droppable = false;
        }
        disableProjects(p.children, parent);
      })
    } 

    let parent = event.source.data[0].parent_project_id
    disableProjects(this.notepadTree, parent)
  } 

  async drop(event: CdkDragDrop<string[]>)
  {
    function flattenTreeDFS(tree) {
      let result = [];
      function traverse(node) 
      {
        result.push(node); 
        if (node.children && node.children.length > 0) 
          {
          node.children.forEach(child => traverse(child)); 
        }
      }
      tree.forEach(rootNode => traverse(rootNode)); 
      return result;
    }

    // Flatten the tree using DFS
    let flattenedTreeDFS = flattenTreeDFS(this.notepadTree);

    let id          = flattenedTreeDFS[event.previousIndex].data.id
    let origin      = flattenedTreeDFS[event.previousIndex].data.ord
    let oldParent   = flattenedTreeDFS[event.previousIndex].data.parent_project_id
    let destination = flattenedTreeDFS[event.currentIndex].data.ord
    let newParent   = flattenedTreeDFS[event.currentIndex].data.parent_project_id

    // Remove drop marks 
    this.notepadTree.forEach(p => {p.data.droppable = false;})

    // Update to another parent
    if (newParent != oldParent) 
    {
      await this.updateNoteProject(id, newParent)
    }

    // Update if same parent
    if (newParent == oldParent) 
    {
      await this.updateNoteOrder(id, oldParent, origin, destination);
    }

    this.store.dispatch(NOTES_ACTIONS.selectNote({id: id}))
  }

  scrollToPaneTitle()
  {
    document.getElementById("notepadPane").scrollIntoView({ behavior: 'smooth' })
  }
  
  clearToTop()
  {
    this.store.dispatch(NOTES_ACTIONS.selectNote({id: 0}))   
    this.scrollToPaneTitle()
  }

  scrollToNote()
  {
    // No notes where to scroll
    if (this.noteId == 0) return;

    // Search in Col1
    var elem = document.getElementById("col1-"+this.noteId.toString());
    if (elem != null)
    {
      elem.scrollIntoView({inline: 'start', block: 'center'});
    }
  }

  editRow(t: any)
  {
    if (t.code == null)
    {
      this.editNote(t);
    }
  }

  editNote(t?: any)
  {
    if (t == null)
    {
      this.noteId=0;
    }
    else
    {
      // Ignore editing a header
      if (t.id == -1) return;     /// REMOVE

      this.noteId=t["id"];
    }
    // Leaving component
    this.store.dispatch(APP_ACTIONS.activateTQpane({ pane:'note' })) 

    this.store.dispatch(NOTES_ACTIONS.selectNote({id: this.noteId}))
    
    this.samApp.saveStore("TQnotepad.filter", this.notepadFilter, "session")
    this.samApp.saveStore("TQnotepad.filterRegEx", this.notepadFilterRegEx, "session")

    this.router.navigate(['/note', this.noteId])
  }

  async updateNoteOrder
  (
    id: number,
    project_id: number,
    origin: number,
    destination: number
  )
  {
    var TQNote = {
      "id"          : id,
      "profile_id"  : this.appState.TQprofileId,
      "patch"       : "order",
      "project_id"  : project_id,
      "origin"      : origin,
      "destination" : destination,
    }
    let res = await this.tqApi.updateNoteOrder(TQNote)

    this.store.dispatch(NOTES_ACTIONS.loadNotesList());
    this.notepadTree = this.buildNotepadTree();
  }

  async updateNoteProject
  (
    id: number,
    destination: number
  ):Promise<number>
  {
    var TQNote = {
      "id"          : id,
      "profile_id"  : this.appState.TQprofileId,
      "patch"       : "project",
      "destination" : destination,
    }

    let res = await this.tqApi.updateNoteProject(TQNote)

    this.store.dispatch(NOTES_ACTIONS.loadNotesList());

    // Return the new position in the new container
    return res['ord']
  }

  buildNotepad()
  {
    this.notepadTree = this.buildNotepadTree();          
  }

  buildNotepadTree()
  {
    function loadNote(tree: TreeNode[], note: any)
    {
      let node = {
        data: {
          is_project: false,
          id: note.id,
          ord: note.ord,
          code: note.code,
          title: note.title,
          parent_project_id: note.project_id,
          droppable: true,
        },
        expanded: false,
        children: []
      }
      tree.push(node)
    }

    function loadProject(tree: TreeNode[], project: any)
    {
      let node: TreeNode = {
        data: {
          is_project: true,
          id: project.id,
          ord: project.ord,
          code: project.code,
          title: project.title,
          parent_project_id: project.parent_project_id,
          role_id: project.role_id,
          role_code: "", // project.parent_project_id ? "" : this.tqSession.getTQRole(project.role_id).code,
          color: project.color,
          droppable: true,
        },
        expanded: true, // (project.parent_project_id == null) ? true : false,
        children: []
      }
      loadChildren(node.children, project)
      
      // Only add the node to the final tree       // if it has children and belongs to the project filter
      if (node.children.length > 0) tree.push(node)   
    }

    function loadChildren(tree: TreeNode[], project: any)
    {
      // First add nodes for the notes
      let projectNotes = notes.filter(n => n.project_id == project.id)
      projectNotes.forEach(n => {
        loadNote(tree, n)
      })

      // Then add nodes for the children projects
      // let childrenProjects = TQprojects.filter(p => p.parent_project_id == project.id)
      let childrenProjects = TQprojects.filter(p => p.parent_project_id == project.id)
      
      childrenProjects.forEach(p => {
        loadProject(tree, p)
      })
    }

    let tree:TreeNode[] = [];

    // Load notes after filtering
    let notes = this.getTQNotes()

    // Load all projects
    let TQprojects = this.projectsState.TQprojects;

    // Select top level projects or selected project
    let projects = [];
    if (this.appState.projectFilterId == 0)
    {
      projects = TQprojects.filter(p => p.parent_project_id == null);
    }
    else
    {
      projects = TQprojects.filter(p => p.id == this.appState.projectFilterId);
    }

    projects.forEach(p => {
      // Add node for each project 
      // with children notes and projects
      loadProject(tree, p)
    })

    setTimeout( () => this.scrollToNote(), 0)

    return tree
  }

  getTQNotes()
  {
    let res: Array<any> = [];

    let notes = this.notesState.TQnotes

    for (var i = 0; i < notes.length; i++)
    {
      let n=notes[i];

      // Ignore filtered tasks
      if (this.filterNote(n)) continue;

      res.push(n)
    }

    return res;
  }

  clearNotepadFilter()
  {
    this.notepadFilterRegEx = null;
    this.samApp.deleteStore("TQnotepad.filterRegEx", "session")
    this.samApp.deleteStore("TQnotepad.filter", "session")

    this.buildNotepad();

    var elem=document.getElementById("NotepadFilter")
    setTimeout(() => {
      elem.focus();
      elem.scrollIntoView({behavior: 'smooth', inline: 'center', block: 'center'});
    }, 100);
  }

  filterNote(n: any): boolean
  {
    if (this.notepadFilterRegEx == null) return false

    this.samApp.saveStore("TQnotepad.filter", this.notepadFilter, "session")
    this.samApp.saveStore("TQnotepad.filterRegEx", this.notepadFilterRegEx, "session")

    // Return whether the note title matches the filter
    return (n['title'].match(new RegExp(this.notepadFilterRegEx,"i")) == null)? true:false;
  }

  clearProjectFilter()
  {
    this.store.dispatch(APP_ACTIONS.projectFiltered({ id: 0, code:'', color:'', list:[]}))
  }

}
